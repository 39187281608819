import { combineReducers } from 'redux'
import app from './app/reducer'
import auth from './auth/reducer'
import loader from './loader/reducer'
import notification from './notification/reducer'
import users from './users/reducer'
import companies from './companies/reducer'
import industries from './industries/reducer'
import occupations from './occupations/reducer'
import workpoints from './working-points/reducer'
import applicant from './applicant/reducer'
import userLogs from './user-logs/reducer'
import chatThreads from './chat-threads/reducer'
import referralProposals from './referral-proposals/reducer'
import topics from './topics/reducer'
import qAndA from './question-and-answers/reducer'
import common from './common/reducer'
import jobPostings from './job-postings/reducer'
import otherJobs from './other-jobs/reducer'
import questioners from './questioners/reducer'
import evaluation from './evaluation/reducer'
import selectionStatusSteps from './selection-status-steps/reducer'
import editHistories from './edit-histories/reducer'
import educationalBackground from './educational-background/reducer'
import englishLevel from './english-level/reducer'
import chineseLevel from './chinese-level/reducer'
import referrals from './referrals/reducer'
import jobPostingForm from './job-posting-form/reducer'
import jobFilter from './job-filter/reducer'
import applicantsStatus from './applicant-status/reducer'
import applicantsNote from './applicants-note/reducer'
import applicationStatuses from './application-statuses/reducer'
import proposalStatuses from './proposal-statuses/reducer'
import adInCharge from './adIncharge/reducer'
import evaluationForm from './evaluation-form/reducer'
import aboutTheMedium from './aboutTheMedium/reducer'
import choices from './choices/reducer'

const reducers = combineReducers({
  app,
  auth,
  choices,
  loader,
  notification,
  users,
  companies,
  industries,
  occupations,
  applicant,
  userLogs,
  chatThreads,
  referralProposals,
  topics,
  qAndA,
  common,
  jobPostings,
  otherJobs,
  questioners,
  evaluation,
  selectionStatusSteps,
  editHistories,
  educationalBackground,
  englishLevel,
  chineseLevel,
  workpoints,
  referrals,
  jobPostingForm,
  jobFilter,
  applicantsStatus,
  applicantsNote,
  applicationStatuses,
  proposalStatuses,
  adInCharge,
  evaluationForm,
  aboutTheMedium,
})

export default reducers
