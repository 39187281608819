import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { FormGroupCheck, Modal } from 'components/molecules'
import { BlockEl, Button, Checkbox } from 'components/atoms'
import { getAboutTheMedium } from 'services/aboutTheMedium'
import { useSet } from 'utils/hooks'
import SearchDropdown from 'components/molecules/SearchDropdown'
function AboutTheMediumModal({ onClose, open, selected, onSelect, title }) {
  const { t } = useTranslation('translation', { keyPrefix: 'newJobPostProposal.step1.aboutTheMediumModal' })
  const dispatch = useDispatch()
  const aboutTheMedium = useSelector((state) => state.aboutTheMedium.items)
  const [activeTab, setActiveTab] = useState(null)
  const { items, reset, toggle, isSelected } = useSet()
  const searchDropdownSet = useSet()

  useEffect(() => {
    if (open) reset(selected)
  }, [open])

  useEffect(() => {
    dispatch(getAboutTheMedium())
  }, [])

  useEffect(() => {
    if (activeTab === null && aboutTheMedium.length > 0) {
      setActiveTab(aboutTheMedium[0].id)
    }
  }, [aboutTheMedium, activeTab])

  const handleClearClick = () => {
    reset([])
  }

  const handleSubmitClick = () => {
    onSelect(items)
    onClose()
  }

  const handleTabClick = (id) => () => {
    setActiveTab(id)
  }

  const hasfilter = searchDropdownSet.items.length > 0
  const mediums = aboutTheMedium.filter(
    (item) => !hasfilter || item.children.some((medium) => searchDropdownSet.isSelected(medium.id))
  )
  const subAboutTheMedium = (aboutTheMedium.find((item) => item.id === activeTab)?.children || []).filter(
    (item) => !hasfilter || searchDropdownSet.isSelected(item.id)
  )

  const selectOptions = aboutTheMedium.reduce((accum, curr) => accum.concat(curr.children), [])

  const handleItemChange = (e) => {
    const checked = e.target.checked
    if (checked === true) {
      const checkCousin = items.filter((id) => !subAboutTheMedium.find((item) => item.id === id))
      reset(subAboutTheMedium.map((item) => item.id).concat(checkCousin))
    } else {
      reset([])
    }
  }
  const checkItems = subAboutTheMedium.every((item) => isSelected(item.id))
  const disable = isSelected(items[0]) === false && checkItems === false

  const selectMed = mediums.map((item) => item.id)

  useEffect(() => {
    if (searchDropdownSet.items.length === 1) {
      setActiveTab(selectMed[0])
    }
  }, [searchDropdownSet])

  return (
    <Modal onClose={onClose} open={open} containerType="adCont" className="modal__contC-w474">
      <div className="modal__choiceContInner1 choiceModal">
        <Modal.Header blockElClass="modal__contHeader modal__contHeader-pd">
          <Modal.Title>{title}</Modal.Title>
          <SearchDropdown selectOptions={selectOptions} searchDropdownSet={searchDropdownSet} />
          <Modal.CloseButton onClick={onClose} blockElClass="selectionModal__close" />
        </Modal.Header>
        <div className="modal__contMain modal__contMain-flex">
          <div className="modal__contMainLeft">
            <ul className="modal__contMainLeftUl">
              {mediums.map((medium) => (
                <BlockEl
                  component="li"
                  bec="modal__contMainLeftLi"
                  modifier={{ active: activeTab === medium.id }}
                  key={medium.id}
                  onClick={handleTabClick(medium.id)}
                >
                  {medium.name}
                </BlockEl>
              ))}
            </ul>
          </div>
          <div className="modal__contMainRight modal__contMainRightCont-active">
            <div className="modal__contMainRightContHead">
              <FormGroupCheck modifier="flex" style={{ marginTop: 0 }}>
                <FormGroupCheck.Item
                  Input={Checkbox}
                  name="selectAll"
                  inputProps={{
                    value: '',
                    onChange: handleItemChange,
                    checked: checkItems,
                  }}
                  type="square"
                  label={t('selectAll')}
                />
              </FormGroupCheck>
              <div className="modal__contMainRightInner">
                <FormGroupCheck
                  style={{ marginTop: '0' }}
                  options={subAboutTheMedium}
                  checkItemProps={({ option }) => ({
                    label: option.name,
                    type: 'square',
                    Input: Checkbox,
                    inputProps: {
                      onChange: () => toggle(option.id),
                      checked: isSelected(option.id),
                      value: option.id,
                    },
                    modifier: 'mgb16',
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal.Footer blockElClass="modal__adContFooter">
        <Button
          type="button"
          modifier="mgr"
          className={clsx('btn__outlineDissable ', disable ? 'btn__outlineDissable-noActive' : '')}
          disabled={disable}
          onClick={handleClearClick}
        >
          {t('clearSelection')}
        </Button>
        <Button
          disabled={disable}
          modifier="mgl16"
          variant="yellowDessable"
          variantMod={{ active: !disable }}
          onClick={handleSubmitClick}
        >
          {t('decide')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AboutTheMediumModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  title: PropTypes.any,
}

export default AboutTheMediumModal
