import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'

import { selectRecommendation, selectRecommendationApplicant } from 'store/referral-proposals/selectors'
import { BlockEl, DownloadLink, NewlineText } from 'components/atoms'
import ChangeHistoryList from '../ChangeHistoryList'
import { fetchEditHistories } from 'services/referralProposals'
import { downloadPath } from 'services/download'
import { EMPLOYMENT_STATUS } from 'utils/constants'
import noop from 'lodash/noop'
import { getUrlFilename, replaceFileName } from 'utils/helper'

const normalizedPortfolios = (referral) => {
  const urls = referral?.other_docs_url || []
  const pdfs = referral?.other_docs_pdf_raw || []

  return urls
    .map(({ url }) => ({ type: 'url', url }))
    .concat(pdfs.map((pdf) => ({ type: 'pdf', url: pdf })))
    .filter(({ url }) => !!url)
}

function SelectionModalRecommenderInfoTab() {
  const { t } = useTranslation('translation', { keyPrefix: 'selection.modal' })
  const candidateTranslation = useTranslation('translation')
  const dispatch = useDispatch()
  const applicant = useSelector(selectRecommendationApplicant)
  const recommendation = useSelector(selectRecommendation)
  const jobApplicantId = recommendation?.job_applicant_id
  const editHistories = useSelector((state) => state.editHistories.items)
  const normalizedOtherDocs = normalizedPortfolios(recommendation)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (!jobApplicantId) return

    dispatch(fetchEditHistories(jobApplicantId))
  }, [jobApplicantId])

  const onClickPortfolio = async (e) => {
    e.preventDefault()

    // Exit early if we are still downloading.
    if (downloading) return

    Promise.all(
      normalizedOtherDocs.map(async ({ url, type }) => {
        if (type === 'pdf') {
          const res = await downloadPath(url)
          if (res) {
            saveAs(res, replaceFileName(getUrlFilename(url)))
          }

          return
        }

        if (checkHttp(url)) {
          window.open(url, '_blank')
        } else window.open('https://' + url, '_blank')
      })
    )
      .catch(noop)
      .finally(() => {
        setDownloading(false)
      })
  }

  const checkHttp = (urlString) => {
    if (urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0) {
      return true
    } else return false
  }

  const getCurrentEmploymentStatus = () => {
    // Regular, Contractual, etc...
    if (Object.values(EMPLOYMENT_STATUS)?.includes(applicant?.current_employment_status))
      return candidateTranslation.t(`candidateSlideout.${applicant?.current_employment_status?.toLowerCase()}`)

    // Others
    return applicant?.current_employment_status
  }

  const showPortfolios = normalizedOtherDocs.length > 0

  if (!applicant || !recommendation) return <></>

  return (
    <>
      <div className="selectionModal__recommender">
        <div className="selectionModal__recomHeader">
          <p className="selectionModal__recomTitle">{applicant.fullName}</p>
          <div className="selectionModal__recomDlArea">
            <DownloadLink
              src={recommendation.work_history_cv_url}
              resolver={() => downloadPath(recommendation.work_history_cv)}
              className="selectionModal__recomLink"
              text={t('curriculumVitae')}
            />
            <BlockEl
              component={DownloadLink}
              bec="selectionModal__recomLink"
              modifier={{ last: !showPortfolios }}
              src={recommendation.resume_url}
              resolver={() => downloadPath(recommendation.resume)}
              text={t('resume')}
            />
            {showPortfolios && (
              <a
                href="#"
                onClick={(e) => onClickPortfolio(e)}
                className="selectionModal__recomLink selectionModal__recomLink-last"
              >
                {t('portfolio')}
              </a>
            )}
          </div>
        </div>
        <dl className="selectionModal__recomDl">
          <dt className="selectionModal__recomDt">{t('recommendationTab.currentAddress')}</dt>
          <dd className="selectionModal__recomDd">{applicant.address || applicant.prefecture?.prefecture}</dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.gender')}</dt>
          <dd className="selectionModal__recomDd">{candidateTranslation.t(`candidate.${applicant?.gender}`)}</dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.currentEmploymentForm')}</dt>
          <dd className="selectionModal__recomDd">{getCurrentEmploymentStatus()}</dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.mostRecentAnnualIncome')}</dt>
          <dd className="selectionModal__recomDd">{applicant.recent_annual_salary}</dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.desiredSalary')}</dt>
          <dd className="selectionModal__recomDd">{applicant.desired_annual_salary}</dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.emergencyContact')}</dt>
          <dd className="selectionModal__recomDd">
            {applicant.ad_person_incharge?.full_name ? (
              <>
                <span className="selectionModal__recomDd-mgr">{applicant.ad_person_incharge.full_name}</span>{' '}
                {applicant.ad_person_incharge.contact_number}
              </>
            ) : (
              applicant.contact_number
            )}
          </dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.remarks')}</dt>
          <dd className="selectionModal__recomDd">
            <NewlineText text={recommendation.special_notes} />
          </dd>
          <dt className="selectionModal__recomDt">{t('recommendationTab.testimonial')}</dt>
          <dd className="selectionModal__recomDd">
            <NewlineText text={applicant.testimonial} />
          </dd>
        </dl>
      </div>

      <div className="selectionModal__recommender selectionModal__recommender-mgt">
        <div className="selectionModal__recomHeader">
          <p className="selectionModal__recomTitle">{t('recommendationTab.changeHistoryTitle')}</p>
        </div>
        <ChangeHistoryList items={editHistories} />
      </div>
    </>
  )
}

export default SelectionModalRecommenderInfoTab
