import React from 'react'
import SearchBox from '../../common/SelectionManagementSearchArea/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from 'components'
import { ADMIN_EVALUATION_OPTIONS } from 'utils/constants'

function EvaluationSearchArea({
  keyword,
  isEvaluated,
  onKeywordChange,
  onSelectChange,
  onFormSubmit,
  onClearFilterClick,
}) {
  const { t } = useTranslation()
  const handleKeywordChange = (e) => onKeywordChange(e.target.value)

  return (
    <form action="" onSubmit={onFormSubmit} className="selection__innerForm searchSide">
      <SearchBox>
        <SearchBox.Top>
          <SearchBox.Title title={t('formLabels.search')} />
          <SearchBox.DropdownArea>
            <SearchBox.FreeWordSearch freeword_label={t('evaluation.searchArea.keyword')}>
              <input
                value={keyword ?? ''}
                type="text"
                className="form__inputText form__inputText-w320px form__inputText-sp100"
                onChange={handleKeywordChange}
              />
            </SearchBox.FreeWordSearch>
            <SearchBox.DropDown dropdown_label={t('evaluation.searchArea.evaluationStatusOption')}>
              <CustomSelect
                placeholder={t('common.pleaseSelect')}
                options={ADMIN_EVALUATION_OPTIONS}
                selected={isEvaluated}
                onChange={onSelectChange}
              />
            </SearchBox.DropDown>
          </SearchBox.DropdownArea>
        </SearchBox.Top>
        <SearchBox.ButtonArea>
          <button className="selection__searchBtn1 f_to_yellow transition_main" onClick={onClearFilterClick}>
            {t('buttonLabels.clearSearch')}
          </button>
          <button className="selection__searchBtn2">{t('formLabels.search')}</button>
        </SearchBox.ButtonArea>
      </SearchBox>
    </form>
  )
}

EvaluationSearchArea.propTypes = {
  keyword: PropTypes.string,
  isEvaluated: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onKeywordChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onClearFilterClick: PropTypes.func.isRequired,
}

export default EvaluationSearchArea
