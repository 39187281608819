import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from 'components'

const Box = ({ children }) => <div className="adminEvaluation__mainLiInner">{children}</div>
Box.propTypes = {
  children: PropTypes.node.isRequired,
}

const ActionDate = ({ date }) => <div className="adminEvaluation__mainDate">{date}</div>
ActionDate.propTypes = {
  date: PropTypes.string.isRequired,
}

const PersonnelDetails = ({ name, company }) => (
  <div className="adminEvaluation__mainValuer">
    <span className="adminEvaluation__mainValuer-txt">{name}</span>
    <span className="adminEvaluation__mainValuer-gray">{company}</span>
  </div>
)

PersonnelDetails.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
}

const Evaluation = ({ children }) => (
  <div className="adminEvaluation__mainValue">
    <span className="adminEvaluation__mainValue-good">{children}</span>
  </div>
)
Evaluation.propTypes = {
  children: PropTypes.node.isRequired,
}

const MutualEvaluation = ({ children }) => <div className="adminEvaluation__mainMutual">{children}</div>
MutualEvaluation.propTypes = {
  children: PropTypes.node.isRequired,
}

const Status = ({ children }) => <div className="adminEvaluation__mainApprovalStatus">{children}</div>
Status.propTypes = {
  children: PropTypes.node.isRequired,
}

const DetailBtn = ({ btnText }) => (
  <div className="adminEvaluation__mainDetailBtn">
    <span className="adminEvaluation__mainDetailBtn-txt">{btnText}</span>
    <SvgIcon.ArrowRight color={'#D7A600'} />
  </div>
)
DetailBtn.propTypes = {
  btnText: PropTypes.string.isRequired,
}

Box.ActionDate = ActionDate
Box.PersonnelDetails = PersonnelDetails
Box.Evaluation = Evaluation
Box.MutualEvaluation = MutualEvaluation
Box.Status = Status
Box.DetailBtn = DetailBtn
export default Box
