import * as ActionTypes from './actionTypes'

export const actionUpdateReferralProposalParams = (patch) => {
  return {
    type: ActionTypes.UPDATE_REFERRAL_PROPOSALS,
    payload: patch,
  }
}

export const actionResetReferralProposalParams = (overrides = {}) => {
  return {
    type: ActionTypes.RESET_REFERRAL_PROPOSAL_PARAMS,
    payload: overrides,
  }
}

export const actionSetSlideoutActiveTab = (tab) => {
  return {
    type: ActionTypes.SET_SLIDEOUT_ACTIVE_TAB,
    payload: tab,
  }
}

export const actionTriggerLastChanged = (date) => {
  return {
    type: ActionTypes.TRIGGER_LAST_CHANGED,
    payload: date instanceof Date ? date.toString() : date,
  }
}

export const actionCloseRPSlideout = () => {
  return {
    type: ActionTypes.CLOSE_RP_SLIDEOUT,
  }
}
