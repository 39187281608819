import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { selectAuthUser } from 'store/auth/selectors'
import { useSelector } from 'react-redux'
import { selectJobPosting } from 'store/job-postings/selectors'
import { accountType } from 'utils/helper'
import { BlockEl } from 'components/atoms'

function JobStatusCard() {
  const jobPosting = useSelector(selectJobPosting)
  const { t } = useTranslation('translation', { keyPrefix: 'jobPosting.status' })
  const user = useSelector(selectAuthUser)
  const account_type = accountType(user)
  const [jobPostingStatus, setJobPostingStatus] = useState()
  let status =
    account_type === 'HR' ||
    ((account_type === 'AD1' || account_type === 'AD2') &&
      (jobPosting?.status === t('underRecruit') ||
        jobPosting?.status === t('closed') ||
        jobPosting?.proposal_count === jobPosting?.number_proposal_request_accepted))
      ? true
      : false

  useEffect(() => {
    if (jobPosting) {
      if (jobPosting.proposal_count === jobPosting.number_proposal_request_accepted) {
        setJobPostingStatus(t('max'))
      } else {
        setJobPostingStatus(jobPosting.status)
      }
    }
  }, [jobPosting])

  return (
    <>
      {status ? (
        <BlockEl bec="evaluation__rightContInner" modifier="status mgb16 static">
          <h2 className="evaluation__rightTitle2">{t('title')}</h2>
          <BlockEl
            component="p"
            bec="evaluation__rightStatus"
            modifier={{
              underRecruitement: jobPostingStatus === t('underRecruit'),
              closed: jobPostingStatus === t('closed'),
              draft: jobPostingStatus === t('draft'),
              archive: jobPostingStatus === t('archive'),
              max: jobPostingStatus === t('max'),
            }}
          >
            {jobPostingStatus}
          </BlockEl>
        </BlockEl>
      ) : (
        ''
      )}
    </>
  )
}

export default JobStatusCard
