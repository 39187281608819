import React, { useState, useEffect } from 'react'
import ApplicantStatus from 'components/organisms/common/ApplicantStatus'
import { useForm, FormProvider } from 'react-hook-form'
import ApplicantConsultantInChargeModal from './ApplicantConsultantInChargeModal'
import ApplicantsModal from '../ApplicantModal'
import { getApplicantsList, getApplicantsListByCompanyId } from 'services/applicant'
import { getAdInChargeList } from 'services/adIncharge'
import { getApplicantsStatus } from 'services/applicantsStatus'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormGroupCheck } from 'components/molecules'
import { Checkbox, Input, Label } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import ConsultantInCharge from 'components/organisms/common/ConsultantInCharge'
import { actionUpdateApplicantsParams } from 'store/applicant/actionCreators'
import { selectCompanyId } from 'store/auth/selectors'
import { USER_STATUSES } from 'utils/constants'

const ApplicantsFilterSideBar = (props) => {
  const { handleSearchClick, initFilter } = props
  const methods = useForm({ mode: 'all' })
  const { register, handleSubmit, setValue } = methods
  const [openConsultantModal, setOpenConsultantModal] = useState(false)
  const state = useSelector((state) => state?.applicant)
  const auth = useSelector((state) => state.auth.user?.data)
  const defaultAdInChargeList = auth.status.id === USER_STATUSES.active ? [auth.id] : []
  const [selectedAdInChargeList, setSelectedAdInChargeList] = useState(defaultAdInChargeList)
  const companyId = useSelector(selectCompanyId)

  const handleConsultantModalClose = () => {
    setOpenConsultantModal(false)
  }

  const handleSubmitInCharge = (ids) => {
    setSelectedAdInChargeList(ids)
  }

  const handleConsultantModal = (e) => {
    e.preventDefault()
    setOpenConsultantModal(true)
    setIsClear(false)
  }

  const [openApplicantModal, setOpenApplicantModal] = useState(false)
  const [applicantsList, setApplicantsList] = useState([])
  const [selectedApplicants, setSelectedApplicants] = useState([])
  const [sideBarSelectedApplicants, setSideBarSelectedApplicants] = useState([])
  const [filterApplicants, setFilterApplicants] = useState([])
  const [isClear, setIsClear] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAdInChargeList())
    dispatch(getApplicantsStatus())
    dispatch(getApplicantsListByCompanyId(companyId)).then((response) => {
      setApplicantsList(response.data || [])
    })
  }, [])

  const handleOpenApplicantModal = (e) => {
    e.preventDefault()
    setOpenApplicantModal(true)
  }

  const handleCloseApplicantModal = () => {
    setOpenApplicantModal(false)
  }

  const handleCheckAllApplicants = (e) => {
    e.preventDefault()
    if (applicantsList.length === selectedApplicants.length) {
      setSelectedApplicants([])
    } else {
      setSelectedApplicants(applicantsList.map((item) => item.id))
    }
  }

  const handleClearApplicantSelection = (e) => {
    e.preventDefault()
    setSelectedApplicants([])
  }

  const onDecideApplicantHandle = () => {
    let decided = applicantsList.filter((item) => selectedApplicants.includes(item.id))
    setSideBarSelectedApplicants(selectedApplicants)
    setFilterApplicants(decided)
    setOpenApplicantModal(false)
  }

  const onHandleApplicantCheck = (e) => {
    e.preventDefault()
    let applicantId = parseInt(e.target.value)
    if (selectedApplicants.includes(applicantId)) {
      setSelectedApplicants(selectedApplicants.filter((item) => item !== applicantId))
    } else {
      setSelectedApplicants([...selectedApplicants, applicantId])
    }
  }

  const onIsCheckedApplicant = (applicantId) => {
    return selectedApplicants.includes(applicantId) ? true : false
  }

  const onHandleApplicantCheckSideBar = (e) => {
    e.preventDefault()
    let applicantId = parseInt(e.target.value)
    if (sideBarSelectedApplicants.includes(applicantId)) {
      setSideBarSelectedApplicants(sideBarSelectedApplicants.filter((item) => item !== applicantId))
    } else {
      setSideBarSelectedApplicants([...sideBarSelectedApplicants, applicantId])
    }
  }

  const onIsCheckedApplicantSideBar = (applicantId) => {
    return sideBarSelectedApplicants.includes(applicantId) ? true : false
  }

  const ApplicantConsultantInCharge = () => {
    return (
      <div className="candidate__conditon">
        <div className="candidate__conditonTitleArea">
          <p className="candidate__conditonTitle">{t('applicant.consultantName')}</p>
          <p
            className="candidate__conditonBtn popModal"
            id="candidateIndustry"
            data-modal="modal"
            data-modalcont="modalCont1"
            onClick={handleConsultantModal}
          >
            {t('applicant.select')}
          </p>
        </div>
        <div className="candidate__conditonCheckArea">
          <div className="candidate__conditonCheckItemWrap">
            <ConsultantInCharge selectedAdInCharge={selectedAdInChargeList} sideFilterDisplay={true} />
          </div>
        </div>
      </div>
    )
  }

  const ApplicantStatusCheckList = () => {
    return (
      <div className="candidate__sideSecGroup">
        <p className="candidate__sideSecGroupTitle">{t('tableHeaders.status')}</p>
        <ApplicantStatus />
      </div>
    )
  }

  const handleClearFilter = (e) => {
    const { company_id, limit } = state.params
    e.preventDefault()
    setSelectedApplicants([])
    setFilterApplicants([])
    setSelectedAdInChargeList([])
    setValue('ad_person_incharge', '')
    setValue('status', '')
    setValue('keyword', '')
    setValue('applicant', '')
    dispatch(
      actionUpdateApplicantsParams({
        company_id,
        status: '',
        keyword: '',
        ad_person_incharge: '',
        applicant: '',
        page: 1,
        limit,
      })
    )
    dispatch(getApplicantsList())
    setIsClear(true)
  }

  useEffect(() => {
    initFilter(selectedAdInChargeList)
  }, [])

  const onSubmit = (data) => {
    data.applicant = sideBarSelectedApplicants
    data.ad_person_incharge = [...selectedAdInChargeList]
    handleSearchClick(data)
  }

  return (
    <div className="candidate__side clearCheckSide" id="candidateSide">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="candidate__sideSec">
            <div className="candidate__sideInner">
              <FormGroup modifier="mgb0 mgt0">
                <Label htmlFor="" className="form__label candidate__label2">
                  {t('fieldLabels.searchForCandidate')}
                </Label>
                <Input type="text" modifier="form__group-mgt0  w100" {...register('keyword')} />
              </FormGroup>
            </div>
            <div className="candidate__sideInner">
              <ApplicantConsultantInCharge />
              <ApplicantStatusCheckList />
            </div>
            <div className="candidate__conditonTitleArea">
              <p className="candidate__conditonTitle">{t('tableHeaders.candidateName')}</p>
              <p
                className="candidate__conditonBtn popModal"
                id="candidateIndustry2"
                data-modal="modal"
                data-modalcont="modalCont2"
                onClick={handleOpenApplicantModal}
              >
                {t('buttonLabels.select')}
              </p>
            </div>
            <div className="candidate__conditonCheckArea">
              <div className="candidate__conditonCheckItemWrap">
                {filterApplicants?.map((row, index) => (
                  <FormGroupCheck.Item
                    key={index}
                    Input={Checkbox}
                    className="candidate__conditonCheckItem"
                    inputProps={{
                      value: row.id,
                      onChange: onHandleApplicantCheckSideBar,
                      blockElClass: `form__checkItemModal`,
                      className: clsx({ checked: onIsCheckedApplicantSideBar(row.id) }),
                    }}
                    label={`${row.lastname} ${row.firstname}`}
                    type="square"
                  />
                ))}
              </div>
            </div>
            <ApplicantsModal
              open={openApplicantModal}
              handleOnClose={handleCloseApplicantModal}
              onHandleCheckAll={handleCheckAllApplicants}
              onHandleCheck={onHandleApplicantCheck}
              applicantsList={applicantsList}
              onIsChecked={onIsCheckedApplicant}
              selectedApplicants={selectedApplicants}
              onDecideHandle={onDecideApplicantHandle}
              handleClearSelection={handleClearApplicantSelection}
            />
          </div>
          <div className="candidate__sideBtnArea">
            <button
              className="btn btn__textDissable btn__textDissable-active btn-miniPd btn-spMgr"
              onClick={handleClearFilter}
            >
              {t('buttonLabels.clearSearch')}
            </button>
            <button className="btn btn__shadowBtn btn-spW100">{t('buttonLabels.narrowDown')}</button>
          </div>
        </form>
      </FormProvider>
      <ApplicantConsultantInChargeModal
        submitConsultantInCharge={handleSubmitInCharge}
        onClose={handleConsultantModalClose}
        open={openConsultantModal}
        isClear={isClear}
      />
    </div>
  )
}

ApplicantsFilterSideBar.propTypes = {
  handleSearchClick: PropTypes.func,
  initFilter: PropTypes.func,
}

export default ApplicantsFilterSideBar
