import React from 'react'
import get from 'lodash/get'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import Box from './Box'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_TIME } from 'utils/constants'
import { formatID, accountType as getAccountType, isParent, getIDPrefix } from 'utils/helper'

function UserLogCard({ userLog }) {
  const { t } = useTranslation()
  const actionDate = dayjs(get(userLog, 'created_at')).format(DATE_FORMAT_TIME)
  const companyName = get(userLog, 'user.company.company_name')
  const companyID = formatID(get(userLog, 'user.company.id'), getIDPrefix(get(userLog, 'user')))
  const accountType = getAccountType(get(userLog, 'user'))
  const accountName = get(userLog, 'user.full_name')
  const accountLevel = isParent(get(userLog, 'user'))
    ? t('userLogs.listing.privilege.parent')
    : t('userLogs.listing.privilege.child')
  const action = get(userLog, 'action.action')
  const jobPostingURL = userLog.job_posting !== null ? `/job-postings/${get(userLog, 'job_posting.id')}` : null
  const jobPostingID = userLog.job_posting !== null ? formatID(get(userLog, 'job_posting.id', null), 'ID') : null
  const jobPostingTitle = get(userLog, 'job_posting.job_title', null)

  return (
    <Box>
      <Box.ActionDate date={actionDate} />
      <Box.CompanyDetails name={companyName} id={companyID} label={t('adminUser.companyId')} />
      <Box.AccountType accountType={accountType} />
      <Box.AccountDetails
        accountName={accountName}
        accountLevel={accountLevel}
        label={t('accountManagement.permissionLabel')}
      />
      <Box.Action action={action} />
      <Box.JobDetails
        jobPostURL={jobPostingURL}
        jobPostTitle={jobPostingTitle}
        jobPostID={jobPostingID}
        label={t('userLogs.jobID')}
      />
    </Box>
  )
}

UserLogCard.propTypes = {
  userLog: PropTypes.any,
}

export default UserLogCard
