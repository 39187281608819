import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { getApplicants } from 'services/applicant'
import { ReactComponent as ArrowIcon } from 'assets/img/arrow3.svg'
import { useCoWorkers } from 'utils/hooks'
import { Button } from 'components/atoms'
import SearchSelectionManagementBox from '../SelectionManagementSearchArea/Box'

const components = {
  DropdownIndicator: () => <ArrowIcon />,
}

const SelectionManagementSearchArea = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [applicantList, setApplicantList] = useState([])
  const { users } = useCoWorkers()
  const { onSubmit, isHR } = props

  const [keyword, setKeyWord] = useState('')
  const [jobChanger, setjobChanger] = useState('')
  const [consultantName, setConsultantName] = useState('')
  const [selectionStatus, setSelectionStatus] = useState([])
  const [actualSelectionStatus, setActualSelectionStatus] = useState([])
  const [selectionStep, setSelectionStep] = useState([])
  const [disableSelectionStep, setDisableSelectionStep] = useState(false)

  const selectionStatusOptions = [
    { value: 1, label: t('selection.sideMenu.recommended') },
    { value: 'under_selection', label: t('selection.sideMenu.under_selection') },
    { value: 9, label: t('selection.sideMenu.unofficial_decision') },
    { value: 10, label: t('selection.sideMenu.joined_the_company') },
    { value: 'decline_or_cancel', label: t('selection.sideMenu.decline_or_cancel') },
  ]

  const selectionStepsOptions = [
    { value: 2, label: t('selectionManagementSearch.documentScreening') },
    { value: 3, label: t('selectionManagementSearch.firstInterview') },
    { value: 4, label: t('selectionManagementSearch.secondInterview') },
    { value: 5, label: t('selectionManagementSearch.thirdInterview') },
    { value: 6, label: t('selectionManagementSearch.fourthInterview') },
    { value: 7, label: t('selectionManagementSearch.fifthInterview') },
    { value: 8, label: t('selectionManagementSearch.lastInterview') },
  ]

  const handleClearBtn = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (!isHR) {
      dispatch(getApplicants()).then((response) => {
        if (response.type === 'GET_APPLICANTS_LIST_SUCCESS') {
          setApplicantList(response.payload.data.data)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (selectionStatus.length > 0) {
      setDisableSelectionStep(true)
      Object.keys(selectionStatus).map((key) => {
        if (selectionStatus[key]['value'] === 'under_selection') {
          setDisableSelectionStep(false)
          return
        }
      })
    } else if (selectionStatus.length === 0) {
      setDisableSelectionStep(false)
    }
  }, [selectionStatus])

  useEffect(() => {
    let selection_statuses = [...selectionStatus]
    let isUnderSelection = false

    if (selectionStatus.length !== 0) {
      const underSelectionChecker = (element) => element.value === 'under_selection'
      isUnderSelection = selectionStatus.some(underSelectionChecker)
    }

    if (selectionStep.length !== 0 && !isUnderSelection) {
      selection_statuses.push(selectionStatusOptions[1])
      setSelectionStatus(selection_statuses)
    }

    if (actualSelectionStatus.length === 0 && selectionStep.length === 0) {
      setSelectionStatus([])
    }
  }, [selectionStep])

  useEffect(() => {
    if (disableSelectionStep) {
      setSelectionStep([])
    }
  }, [disableSelectionStep])

  const handleSelectionSteps = (e) => {
    setSelectionStep(e)
  }

  const handleSelectionStatus = (e) => {
    setSelectionStatus(e)
    setActualSelectionStatus(e)
  }

  const handleUserChange = (val) => {
    setConsultantName(val?.id ?? null)
  }

  const handleApplicantChange = (val) => {
    setjobChanger(val?.id ?? null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    onSubmit({
      keyword,
      jobChanger,
      consultantName,
      selectionStatuses: selectionStatus,
      selectionSteps: selectionStep,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <SearchSelectionManagementBox>
        <SearchSelectionManagementBox.Top>
          <SearchSelectionManagementBox.Title title={t('selectionManagementSearch.searchTitle')} />
          {!isHR && (
            <SearchSelectionManagementBox.FreeWordSearch freeword_label={t('selectionManagementSearch.searchFreeWord')}>
              <input type="text" className="selection__searchInput" onChange={(e) => setKeyWord(e.target.value)} />
            </SearchSelectionManagementBox.FreeWordSearch>
          )}
          <SearchSelectionManagementBox.DropdownArea>
            {isHR && (
              <SearchSelectionManagementBox.FreeWordSearch
                freeword_label={t('selectionManagementSearch.searchFreeWord')}
              >
                <input type="text" className="selection__searchInput" onChange={(e) => setKeyWord(e.target.value)} />
              </SearchSelectionManagementBox.FreeWordSearch>
            )}
            {!isHR && (
              <SearchSelectionManagementBox.DropDown dropdown_label={t('selectionManagementSearch.searchJobChanger')}>
                <Select
                  options={applicantList}
                  className="selection__searchSelect selection__multiple_select"
                  placeholder={''}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.fullName}
                  onChange={handleApplicantChange}
                  isClearable={true}
                />
              </SearchSelectionManagementBox.DropDown>
            )}
            {!isHR && (
              <SearchSelectionManagementBox.DropDown
                dropdown_label={t('selectionManagementSearch.searchConsultantName')}
              >
                <Select
                  className="selection__searchSelect selection__multiple_select"
                  options={users}
                  onChange={handleUserChange}
                  components={components}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.full_name}
                  placeholder={t('hints.pleaseSpecify')}
                  isClearable={true}
                />
              </SearchSelectionManagementBox.DropDown>
            )}
            <SearchSelectionManagementBox.DropDown
              dropdown_label={t('selectionManagementSearch.searchSelectionStatus')}
            >
              <Select
                isMulti
                className="selection__searchSelect selection__multiple_select"
                options={selectionStatusOptions}
                noOptionsMessage={() => t('common.dropDownNoOption')}
                onChange={(e) => handleSelectionStatus(e)}
                components={components}
                placeholder={''}
              />
            </SearchSelectionManagementBox.DropDown>
            <SearchSelectionManagementBox.DropDown dropdown_label={t('selectionManagementSearch.searchSelectionStep')}>
              <Select
                isMulti
                className={`selection__searchSelect selection__multiple_select${
                  disableSelectionStep ? '-disableSelect' : ''
                }`}
                onChange={(e) => handleSelectionSteps(e)}
                options={selectionStepsOptions}
                noOptionsMessage={() => t('common.dropDownNoOption')}
                components={components}
                placeholder={''}
                value={disableSelectionStep ? [] : selectionStep}
              />
            </SearchSelectionManagementBox.DropDown>
          </SearchSelectionManagementBox.DropdownArea>
        </SearchSelectionManagementBox.Top>

        <SearchSelectionManagementBox.ButtonArea>
          <Button bec="selection__searchBtn1" onClick={handleClearBtn}>
            {t('selectionManagementSearch.clearSearchBtn')}
          </Button>
          <Button bec="selection__searchBtn2" type="submit" id="submit">
            {t('selectionManagementSearch.searchBtn')}
          </Button>
        </SearchSelectionManagementBox.ButtonArea>
      </SearchSelectionManagementBox>
    </form>
  )
}

SelectionManagementSearchArea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isHR: PropTypes.bool.isRequired,
}

export default SelectionManagementSearchArea
