import * as types from './actionTypes'

export function actionCreateUser(userDetails) {
  return {
    type: types.CREATE_USER,
    payload: userDetails,
  }
}

export function actionUpdateUser(userDetails) {
  return {
    type: types.UPDATE_USER,
    payload: userDetails,
  }
}

export function actionSearchUser(userList) {
  return {
    type: types.SEARCH_USER,
    payload: userList,
  }
}

export function actionSetSearchCriteria(payload) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: payload,
  }
}

export function actionClearModalValues() {
  return {
    type: types.CLEAR_MODAL_VALUES,
  }
}

export function actionSetModalValues(userDetails) {
  return {
    type: types.SET_MODAL_VALUES,
    payload: userDetails,
  }
}
