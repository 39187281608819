import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSearchParams } from 'store/users/selectors'
import { changeSearchCriteria, searchUser } from 'services/users'
//import { changeSearchCriteria } from 'services/users'
import AccountPermissionSelect from '../AccountPermissionSelect'
import UserStatusSelect from '../UserStatusSelect'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/atoms'

function AccountManagementListFilter() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handlePermissionChange = (id) => {
    dispatch(changeSearchCriteria({ permissions: [id] }))
  }

  const handleStatusChange = (id) => {
    dispatch(changeSearchCriteria({ statuses: [id] }))
  }

  const { keyword, permissions, statuses } = useSelector(selectUserSearchParams)
  const statusSelect = statuses[0] ?? null
  const permissionSelect = permissions[0] ?? null
  const keywordSelect = keyword

  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(searchUser())
  }

  const handleKeywordChange = (e) => {
    dispatch(changeSearchCriteria({ keyword: e.target.value }))
  }

  return (
    <form onSubmit={handleSearch}>
      <div className="selection__searchArea">
        <div className="selection__searchAreaTop">
          <p className="selection__searchTitle1">{t('userAccount.title')}</p>
          <div className="selection__searchFlex">
            <div className="selection__searchGroup1">
              <div className="form__customSelectArea form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.keyword')}</div>
                <input
                  type="text"
                  value={keywordSelect}
                  onChange={handleKeywordChange}
                  className="form__inputText form__inputText-w320px form__inputText-sp100"
                />
              </div>
            </div>

            <div className="selection__searchGroup1">
              <div className="form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.filterByStatus')}</div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <UserStatusSelect
                      onChange={handleStatusChange}
                      value={statusSelect}
                      formControlProps={{ fullWidth: true }}
                    />
                  </div>
                  <div className="form__customSelectBack"></div>
                </div>
              </div>
            </div>

            <div className="selection__searchGroup1">
              <div className="form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.filterByPermission')}</div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <AccountPermissionSelect onChange={handlePermissionChange} value={permissionSelect} />
                    <div className="form__customSelectBack"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="selection__searchAreaBottom">
          <Button className="selection__searchBtn1" blockElClass="" onClick={() => window.location.reload(true)}>
            {t('userAccount.clearFilter')}
          </Button>
          <Button className="selection__searchBtn2" blockElClass="" type="submit">
            {t('userAccount.searchBtn')}
          </Button>
        </div>
      </div>
    </form>
  )
}
export default AccountManagementListFilter
