import find from 'lodash/find'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import negate from 'lodash/negate'
import padStart from 'lodash/padStart'
import qs from 'query-string'
import { USER_STATUSES, USER_TYPES, USER_TYPE_KEYS } from './constants'
import invert from 'lodash/invert'
import clsx from 'clsx'
import dayjs from 'dayjs'

export function syncErrorsToForm(errors, setError) {
  Object.keys(errors).forEach((key) => {
    setError(key, { message: errors[key] })
  })
}

export function extractString(payload) {
  const type = typeof payload

  if (type === 'string') return payload

  // Get the first property
  if (type === 'object') return extractString(payload[Object.keys(payload)[0]])

  // Get the first index
  if (isArray(payload)) return payload[0]

  return payload
}

export const objectFlip = invert

/**
 * Parse axios response to extract the server response
 *
 * @param {any} axiosPromise
 * @returns {Promise}
 */
export function parseAxiosResponse(axiosPromise) {
  return axiosPromise.then(
    (res) => res.data,
    (err) => {
      if (err.networkError) return Promise.reject(err)
      return Promise.reject(err?.response?.data)
    }
  )
}

export function formatID(id, prefix = 'ID') {
  return `${prefix}${padStart((id || '').toString(), 6, 0)}`
}

export function isHuunoAdmin(user) {
  const group = find(get(user, 'groups', null), {
    id: USER_TYPES.admin,
  })

  return !!group
}

export function isParent(user) {
  return get(user, 'groups.0.is_parent', false)
}

function _getAccountTypes(user) {
  const groups = user?.groups || []
  if (groups.length === 0) return []

  return groups.map((group) => {
    switch (group.id) {
      case USER_TYPES.ad1Child:
      case USER_TYPES.ad1Parent:
        return USER_TYPE_KEYS.AD1
      case USER_TYPES.ad2Child:
      case USER_TYPES.ad2Parent:
        return USER_TYPE_KEYS.AD2
      case USER_TYPES.hrChild:
      case USER_TYPES.hrParent:
        return USER_TYPE_KEYS.HR
      case USER_TYPES.admin:
        return USER_TYPE_KEYS.ADMIN
      default:
        return null
    }
  })
}

function isSetupInvitation(user) {
  return (
    (isParent(user) &&
      !(
        user.company.first_name &&
        user.company.last_name &&
        user.company.year_established &&
        user.company.account_settlement_month &&
        user.company.employee_count
      )) ||
    ((accountType(user) === 'AD1' || accountType(user) === 'AD2') &&
      !(user.firstname && user.lastname && user.contact_number)) ||
    (accountType(user) === 'HR' && !user.firstname && !user.lastname)
  )
}

export function accountType(user) {
  return get(_getAccountTypes(user), [0], null)
}

export function inAccountType(user, accountType) {
  return _getAccountTypes(user).includes(accountType)
}

export function isCompanyAccountNeedSetup(user) {
  let view = false
  if (isParent(user)) {
    if (
      !user.company.first_name &&
      !user.company.last_name &&
      !user.company.year_established &&
      !user.company.account_settlement_month &&
      !user.company.employee_count
    ) {
      view = true
    }
  }
  return view
}

export function isUserAccountNeedSetup(user) {
  let view = false
  if (accountType(user) === 'AD1' || accountType(user) === 'AD2') {
    if (!user.firstname && !user.lastname && !user.contact_number) {
      view = true
    }
  }
  return view
}

export function isHrUserAccountNeedSetup(user) {
  let view = false
  if (accountType(user) === 'HR') {
    if (!user.firstname && !user.lastname) {
      view = true
    }
  }
  return view
}

export function getHomePage(user) {
  switch (accountType(user)) {
    case 'HR':
      return isSetupInvitation(user) && !user.changed_default_pass
        ? '/invitation'
        : isSuspended(user)
        ? '/job-management'
        : user.has_proposal_job_posting && !user.has_referral_job_posting
        ? '/suggestion-management'
        : '/selection-management'
    case 'AD1':
    case 'AD2':
      return isSetupInvitation(user) ? '/invitation' : '/jobSearch'
    case 'ADMIN':
      return '/admin/company-management'
    default:
      return '/'
  }
}

// TODO: Remove this
export function getJobPostingUrl(jobPosting) {
  const url = new URL(window.location.origin)
  url.pathname = `/job-postings/${get(jobPosting, 'id')}`
  return url.href
}

export const notEmpty = negate(isEmpty)

export function calculateBEM(prefix, mods) {
  return clsx(prefix, calculateModifiers(prefix, mods))
}

/**
 * Calcuate modifier by the given prefix
 *
 * @param {String} prefix
 * @param {any} mods
 * @returns
 */
export function calculateModifiers(prefix, mods) {
  let obj = {}
  const thePrefix = (prefix || '').split(' ').filter(notEmpty)[0]
  const arrange = (mod) => (obj[`${thePrefix}-${mod}`] = true)
  const subject = clsx(mods)

  subject
    .split(' ')
    .filter((i) => !isEmpty(i))
    .forEach(arrange)

  return obj
}

/**
 * returns updated object
 * same functionality as Object.assign()
 * @param oldObject
 * @param updatedProperties
 * @returns object
 */

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

export const findObjectIndex = (arrayObject, value) => {
  return arrayObject.findIndex((obj) => obj.name === value)
}

export const removeByIndex = (array, index) => {
  return array.splice(index, 1)
}

export function assignRefs(refs = []) {
  return (el) => {
    refs.forEach((ref) => {
      if (ref === null) return

      if (typeof ref === 'function') return ref(el)

      if ('current' in ref) return (ref.current = el)
    })
  }
}

export function range(start, end) {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

export const getUrlFilename = (src = '') => qs.parseUrl(src).url.split('/').pop()

export const parseDateTime = (date, time) => dayjs(date).hour(time.split(':')[0]).minute(time.split(':')[1]).second(0)

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export function makeMinMaxValidators({ minField, maxField, getValues }) {
  return {
    min: (value) => {
      const max = +getValues(maxField)
      return +value <= max
    },
    max: (value) => {
      const min = +getValues(minField)
      return +value >= min
    },
  }
}

export function makeFormGroupStateGetter(getFieldState) {
  const getFormGroupState = ({ isDirty, isTouched, invalid }, opts = {}) => {
    const { excludeSucccess = false } = opts
    return omit(
      {
        error: invalid,
        success: !invalid && (isDirty || isTouched),
      },
      excludeSucccess ? ['success'] : []
    )
  }
  const getFgStateByName = (name, opts = {}) => getFormGroupState(getFieldState(name), opts)
  const and = (...args) => {
    if (args.length === 0)
      return {
        success: false,
        error: false,
      }
    return args.reduce(
      (accum, curr) => ({
        success: accum.success && curr.success,
        error: accum.error || curr.error,
      }),
      args[0]
    )
  }
  return {
    fromFieldState: getFormGroupState,
    byName: getFgStateByName,
    and,
  }
}

export function getHRInCharge(referralProposal) {
  return referralProposal?.job_posting?.user_id
}

export function createGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function toMB(size) {
  return size / 1024 / 1024
}

export function getIDPrefix(user) {
  const account = accountType(user)

  return account === 'AD1' || account === 'AD2' ? 'AD' : account
}

export function isSuspended(user) {
  const isHuunoAdmin = inAccountType(user, USER_TYPE_KEYS.ADMIN)

  return isHuunoAdmin ? false : user.status.id === USER_STATUSES.suspended
}

export function replaceFileName(filename) {
  if (/^[^_]+(?=_)_/.test(filename)) {
    return filename.replace(/^[^_]+(?=_)_/, '')
  } else {
    return filename
  }
}
