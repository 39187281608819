import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

const NavMenuItem = (props) => {
  const { title, href, exact = false } = props

  return (
    <li className="header__navLi" key={title} to={href}>
      <NavLink
        exact={exact}
        className={(active) => clsx('header__navLink', { 'header__navLink-active': active })}
        to={href}
      >
        {title}
      </NavLink>
    </li>
  )
}

NavMenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  href: PropTypes.string,
  exact: PropTypes.bool,
}

export default NavMenuItem
