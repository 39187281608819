import produce from 'immer'
import merge from 'lodash/merge'

import * as ActionTypes from './actionTypes'
import {
  fetchReferralProposal,
  updateReferralProposal,
  fetchReferralProposals,
  fetchReferralProposalSteps,
} from 'services/referralProposals'
import { markAllThreadMessagesAsRead } from 'services/chatThreads'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialState = {
  savingState: 'pending',
  item: null,
  error: null,
  listState: 'pending',
  listError: null,
  items: [],
  params: {
    limit: 10,
    page: 1,
    selection_status: null,
    type: 'referral',
    selection_step: null,
    keyword: null,
    consultantName: null,
    jobChanger: null,
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
  slideOut: {
    state: 'pending',
    item: null,
    error: null,
    open: false,
    tab: 'message',
  },
  steps: {
    state: 'pending',
    items: [],
    error: null,
  },
  lastChanged: null,
}

const reducer = produce((draft, { type, payload, meta }) => {
  switch (type) {
    case fetchReferralProposal.pending:
      draft.slideOut.state = 'pending'
      break
    case fetchReferralProposal.fulfilled:
      draft.slideOut.state = 'fulfilled'
      draft.slideOut.item = payload
      draft.slideOut.chatThread = payload.chat_thread
      draft.slideOut.open = true
      break
    case fetchReferralProposal.failure:
      draft.slideOut.state = 'failure'
      draft.slideOut.error = payload?.message
      break
    case updateReferralProposal.pending:
      draft.savingState = 'pending'
      break
    case updateReferralProposal.fulfilled: {
      draft.savingState = 'fulfilled'
      updateStoreReferralProposal(draft, payload)
      break
    }
    case updateReferralProposal.failure:
      draft.savingState = 'failure'
      draft.error = payload?.message
      break
    case ActionTypes.UPDATE_REFERRAL_PROPOSALS:
      draft.params = Object.assign(draft.params, payload)
      break
    case fetchReferralProposals.pending:
      draft.listState = 'pending'
      break
    case fetchReferralProposals.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break
    case fetchReferralProposals.failure:
      draft.listState = 'failure'
      draft.listError = payload.message
      break
    case fetchReferralProposalSteps.pending:
      draft.steps.state = 'pending'
      break
    case fetchReferralProposalSteps.fulfilled: {
      const { steps, ...data } = payload.data
      draft.steps.state = 'fulfilled'
      draft.steps.items = steps
      updateStoreReferralProposal(draft, data)
      break
    }
    case fetchReferralProposalSteps.failure:
      draft.steps.state = 'failure'
      draft.steps.error = payload.message
      break
    case ActionTypes.RESET_REFERRAL_PROPOSAL_PARAMS:
      draft.params = Object.assign(draft.params, initialState.params, payload)
      break
    case ActionTypes.SET_SLIDEOUT_ACTIVE_TAB:
      draft.slideOut.tab = payload
      break
    case markAllThreadMessagesAsRead.pending: {
      const threadId = meta.args[0]
      const item = getReferralProposalByThreadId(draft, threadId)

      if (item) {
        item.read = true
        updateStoreReferralProposal(draft, item)
      }
      break
    }

    case markAllThreadMessagesAsRead.failure: {
      const threadId = meta.args[0]
      const item = getReferralProposalByThreadId(draft, threadId)

      if (item) {
        item.read = false
        updateStoreReferralProposal(draft, item)
      }
      break
    }

    case ActionTypes.TRIGGER_LAST_CHANGED:
      draft.lastChanged = payload
      break

    case ActionTypes.CLOSE_RP_SLIDEOUT:
      draft.slideOut.open = false
      break

    case AUTH_SIGNOUT:
      return initialState

    default:
      return draft
  }
}, initialState)

function updateStoreReferralProposal(draft, payload) {
  if (payload.id === draft.slideOut.item?.id) {
    draft.slideOut.item = merge(draft.slideOut.item, payload)
  }
  const index = draft.items.findIndex(({ id }) => payload.id === id)

  if (index !== -1) {
    draft.items[index] = merge(draft.items[index], payload)
  }
}

function getReferralProposalByThreadId(draft, threadId) {
  let refProp
  const idx = draft.items.findIndex(({ chat_thread }) => chat_thread?.id && chat_thread.id === threadId)

  if (idx !== -1) {
    refProp = draft.items[idx]
  }

  if (draft.slideOut.item?.chat_thread?.id === threadId) {
    refProp = draft.slideOut.item
  }

  return refProp
}

export default reducer
