import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import merge from 'lodash/merge'

import { BlockEl, DateText, Input } from 'components/atoms'
import { DATE_FORMAT_JAPANESE } from 'utils/constants'

function MonthDatePicker(props) {
  const {
    inputProps: propInputProps = {},
    altInputFormat = DATE_FORMAT_JAPANESE,
    dateFormat = 'YYYY-MM',
    onChange,
    value,
  } = props
  const { t } = useTranslation('translation', { keyPrefix: 'dateSymbols' })
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const currentYear = new Date().getFullYear()

  const [year, setYear] = useState(currentYear)
  const [openCalendar, setOpenCalendar] = useState(false)

  const instance = useMemo(() => dayjs(value, dateFormat), [value, dateFormat])

  const formattedDate = instance.isValid() ? instance.format(altInputFormat) : ''

  const inputProps = merge(
    {
      blockElClass: 'form__date',
      autoComplete: 'off',
      readOnly: true,
      value: formattedDate,
    },
    propInputProps,
    {
      onClick: () => setOpenCalendar(true),
    }
  )

  const handleMonthClick = (m) => () => {
    if (isDateDisabled(m)) return

    if (typeof onChange === 'function') {
      onChange(
        dayjs()
          .year(year)
          .month(m - 1)
          .format(dateFormat)
      )
    }
    setOpenCalendar(false)
  }

  const isDateDisabled = (m) => {
    return m < new Date().getMonth() + 1
  }

  const isActive = (m) => {
    return instance.month() + 1 === m && instance.year() === year
  }

  return (
    <div className="form__selectWrap">
      <Input {...inputProps} />
      <BlockEl blockElClass="form__cal" modifier={{ open: openCalendar }}>
        <div
          className="form__calBg"
          onClick={() => {
            setOpenCalendar(false)
          }}
        ></div>
        <BlockEl blockElClass="form__calHeader" modifier={{ notThisYear: +currentYear !== +year }}>
          <span className="form__calHeader-back" onClick={() => setYear(+year - 1)}></span>
          <p>
            <DateText format={DATE_FORMAT_JAPANESE} />
          </p>
          <span className="form__calHeader-next" onClick={() => setYear(+year + 1)}></span>
        </BlockEl>
        <div className="form__calCont">
          <ul className="form__calContUl">
            {months.map((m) => (
              <BlockEl
                blockElClass="form__calContLi"
                key={m}
                modifier={{ disabled: isDateDisabled(m), active: isActive(m) }}
                onClick={handleMonthClick(m)}
              >
                {m}
                {t('month')}
              </BlockEl>
            ))}
          </ul>
        </div>
      </BlockEl>
    </div>
  )
}

MonthDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  inputProps: PropTypes.shape(Input.propTypes),
  altInputFormat: PropTypes.string,
  dateFormat: PropTypes.string,
}

export default MonthDatePicker
