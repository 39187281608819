import React, { useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { calculateModifiers } from 'utils/helper'
import { useBEM } from 'utils/hooks'
import { BlockEl, Button } from '../../atoms'
import clsx from 'clsx'

function ModalHeader(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__contHeader', props: inProps })

  return <div {...props}>{children}</div>
}

ModalHeader.propTypes = {
  children: PropTypes.any,
  modifier: PropTypes.string,
  blockElClass: PropTypes.string,
}

function ModalBody(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__contBody', props: inProps })

  return <div {...props}>{children}</div>
}

ModalBody.propTypes = {
  children: PropTypes.any,
  blockElClass: PropTypes.string,
}

function ModalBtnArea(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__btnArea', props: { modifier: 'fe', ...inProps } })

  return <div {...props}>{children}</div>
}

ModalBtnArea.propTypes = {
  children: PropTypes.any,
  modifier: PropTypes.string,
  blockElClass: PropTypes.string,
}

function ModalTitle(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__contTitle', props: inProps })

  return <h2 {...props}>{children}</h2>
}

ModalTitle.propTypes = {
  children: PropTypes.any,
  blockElClass: PropTypes.string,
}

function ModalTitleWrap(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__contTitleWrap', props: inProps })

  return <div {...props}>{children}</div>
}

ModalTitleWrap.propTypes = {
  children: PropTypes.any,
  blockElClass: PropTypes.string,
}

function ModalInner(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__inner', props: inProps })

  return <div {...props}>{children}</div>
}

ModalInner.propTypes = {
  children: PropTypes.any,
  modifier: PropTypes.string,
  blockElClass: PropTypes.string,
}

function ModalCloseButton(inProps) {
  const props = useBEM({ initial: 'modal__close', props: inProps })

  return <div {...props}></div>
}

ModalCloseButton.propTypes = {
  blockElClass: PropTypes.string,
}

const ModalButton = React.forwardRef(({ blockElClass = 'modal__btn', ...props }, ref) => {
  return <Button {...{ ...props, blockElClass, ref }} />
})

ModalButton.displayName = 'ModalButton'

ModalButton.propTypes = Button.propTypes

const modalRoot = document.getElementById('modal-root')

function ModalText(inProps) {
  const { children, ...props } = useBEM({ initial: 'modal__text', props: inProps })

  return <p {...props}>{children}</p>
}

ModalText.propTypes = {
  children: PropTypes.any,
  blockElClass: PropTypes.string,
}

function Modal(props) {
  const {
    children,
    open = false,
    onClose = noop,
    innerProps = {},
    containerType = 'contC',
    containerClass = '',
    containerModifier = '',
    modifier,
    containerBlockElClass,
    blockElClass = 'modal',
    containerRef,
  } = props
  const backdropRef = useRef(null)
  const rootRef = useRef(null)
  const modalActiveClass = `${blockElClass}-active`

  useEffect(() => {
    const el = document.createElement('div')
    el.classList.add(blockElClass)
    rootRef.current = el

    modalRoot.appendChild(el)

    return () => {
      modalRoot.removeChild(el)
    }
  }, [blockElClass])

  useEffect(() => {
    if (rootRef.current) {
      open ? rootRef.current.classList.add(modalActiveClass) : rootRef.current.classList.remove(modalActiveClass)
    }

    if (open && backdropRef.current !== null) {
      const current = backdropRef.current
      const onClick = () => {
        onClose({ type: 'backdrop' })
      }

      current.addEventListener('click', onClick)

      return () => {
        current.removeEventListener('click', onClick)
      }
    }
  }, [open, backdropRef.current, modalActiveClass, onClose])

  useEffect(() => {
    if (rootRef.current === null) return
    const classes = calculateModifiers(blockElClass, modifier)
    /** @type {HTMLElement} */
    const el = rootRef.current
    // Add classes
    Object.keys(classes).map((e) => el.classList.contains(e) || el.classList.add(e))
    // Remove unnecessary classes
    el.classList.forEach((e) => {
      // Exclude modal and modal-active classes
      if ([blockElClass, modalActiveClass].includes(e)) return

      if (!(e in classes)) el.classList.remove(e)
    })
  }, [modifier, blockElClass, modalActiveClass])

  const containerStyles = useMemo(() => {
    let styles = {}

    switch (containerType) {
      case 'contSlideCont':
        styles.transform = open ? 'translateX(0px)' : 'translateX(100%)'
        return styles
      case 'contSlideCont2':
        styles.transform = open ? 'translateX(0px)' : 'translateX(-100%)'
        return styles
      default:
        styles.display = open ? 'block' : 'initial'
        return styles
    }
  }, [open, containerType])

  if (rootRef.current === null) return <></>

  return ReactDOM.createPortal(
    <>
      <div ref={backdropRef} className="modal__backdrop"></div>
      <ModalInner {...innerProps}>
        <BlockEl
          componentRef={containerRef}
          style={containerStyles}
          blockElClass={containerBlockElClass ?? `modal__${containerType}`}
          modifier={containerModifier}
          className={clsx([{ modal__cont: !containerBlockElClass }, containerClass])}
        >
          {children}
        </BlockEl>
      </ModalInner>
    </>,
    rootRef.current
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modifier: PropTypes.string,
  blockElClass: PropTypes.string,
  innerProps: PropTypes.shape(ModalInner.propTypes),
  containerType: PropTypes.oneOf([
    'contA',
    'contB',
    'contC',
    'contSlideCont',
    'contSlideCont2',
    'adCont',
    'contE',
    'contF',
  ]),
  containerBlockElClass: PropTypes.string,
  containerModifier: PropTypes.any,
  containerClass: PropTypes.string,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalBtnArea
Modal.BtnArea = ModalBtnArea
Modal.Title = ModalTitle
Modal.TitleWrap = ModalTitleWrap
Modal.CloseButton = ModalCloseButton
Modal.Text = ModalText
Modal.Button = ModalButton

export default Modal
