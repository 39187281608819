import React, { useEffect, useState, useRef } from 'react'
import { getApplicantDetails, updateApplicant } from 'services/applicant'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { ReactComponent as PlusIcon } from 'assets/img/plus-icon2.svg'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/atoms'
import { Modal, ConfirmModalContent } from 'components/molecules'
import { CircularProgress } from '@mui/material'
import CandidatePortfolioInput from './CandidatePortfolioInput'
import CandidatePortfolioPdfItem from './CandidatePortfolioPdfItem'
import CandidatePortfolioUrlItem from './CandidatePortfolioUrlItem'
import { createGUID, replaceFileName } from 'utils/helper'

const CandidateResumePortfolioTab = ({ applicantId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [applicant, setApplicant] = useState({})
  const [otherDocsUrlDisplay, setOtherDocsUrlDisplay] = useState([])
  const [otherDocsPdfDisplay, setOtherDocsPdflDisplay] = useState([])
  const [portfolioUrl, setPortfolioUrl] = useState('')
  const [portfolioType, setPortfolioType] = useState('url')
  const [loading, setLoading] = useState(false)
  const [showPortfolioInput, setShowPortfolioInput] = useState(false)
  const [showCircular, setShowCircular] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pdfToUpload, setOtherPdfToUpload] = useState(null)
  const portfolioToDelete = useRef(null)
  const otherDocsUrl = useRef([])
  const otherDocsPdf = useRef([])

  useEffect(() => {
    getApplicantData()
  }, [])

  const getApplicantData = () => {
    setLoading(true)
    dispatch(getApplicantDetails(applicantId)).then((response) => {
      if (response.type === 'GET_APPLICANT_DETAILS_SUCCESS') {
        manageApplicantData(response.payload.data.data)
      } else setLoading(false)
    })
  }

  const manageApplicantData = async (applicantData) => {
    otherDocsUrl.current = []
    otherDocsPdf.current = []
    setShowCircular(true)

    if (isJsonParsable(applicantData.other_docs_url)) {
      const tempDocsUrl = JSON.parse(applicantData.other_docs_url)
      otherDocsUrl.current = tempDocsUrl
      setOtherDocsUrlDisplay(tempDocsUrl)
    }

    if (isPdfParsable(applicantData.other_docs_pdf)) {
      otherDocsPdf.current = applicantData?.other_docs_pdf?.split('\\n')
    }
    setOtherDocsPdflDisplay(applicantData.other_docs_pdf_urls)
    setApplicant(applicantData)
    setShowCircular(false)
    setLoading(false)
  }

  const isPdfParsable = (string) => {
    try {
      if (string?.length === 0) return false
      string.split('\\n')
    } catch {
      return false
    }
    return true
  }

  const isJsonParsable = (string) => {
    try {
      JSON.parse(string)
    } catch {
      return false
    }
    return true
  }

  const handleRadioClick = (e) => {
    setPortfolioType(e.target.value)
    resetStates()
  }

  const resetStates = () => {
    setPortfolioUrl('')
    setOtherPdfToUpload(null)
  }

  const handleFileInput = async (e) => {
    const { files } = e.target
    if (files?.length > 0) {
      setOtherPdfToUpload(files[0])
    }
  }

  const handleRemoveFile = async () => {
    setOtherPdfToUpload(null)
  }

  const checkIfFilled = () => {
    if (portfolioType === 'url') {
      return !!portfolioUrl
    } else {
      return !!pdfToUpload
    }
  }

  const handleSubmit = (submitionType) => {
    setShowModal(false)
    setLoading(true)
    let odu = otherDocsUrl.current
    let odp = otherDocsPdf.current
    let pdfToDelete = null
    let applicantCopy = { ...applicant }
    if (submitionType === 'add') {
      if (portfolioType === 'url') {
        const newUrl = {
          id: createGUID(),
          option: 'url',
          url: portfolioUrl,
          pdf: null,
        }
        odu = odu.concat(newUrl)
      }
    } else if (submitionType === 'delete') {
      if (portfolioType === 'url') {
        odu.splice(portfolioToDelete.current, 1)
      }
      if (portfolioType === 'pdf') {
        pdfToDelete = odp[portfolioToDelete.current]
      }
    }

    switch (applicantCopy['status']) {
      case t('candidate.beforeSup'):
        applicantCopy['status'] = 1
        break

      case t('candidate.underSup'):
        applicantCopy['status'] = 2
        break

      case t('candidate.notApplicable'):
        applicantCopy['status'] = 3
        break

      case t('candidate.timeLag'):
        applicantCopy['status'] = 4
        break

      case t('candidate.EndSup'):
        applicantCopy['status'] = 5
        break
    }

    applicantCopy = {
      ...applicantCopy,
      other_docs_url: odu,
      other_docs_pdf: odp,
      other_pdfs: submitionType === 'delete' ? [] : [pdfToUpload],
      ad_person_incharge: applicantCopy?.ad_person_incharge?.id ?? Number(applicantCopy?.ad_person_incharge),
      prefecture: applicantCopy?.prefecture?.id ?? applicantCopy?.prefecture_id,
      action: submitionType,
      pdfToDelete: pdfToDelete ?? null,
    }
    dispatch(updateApplicant(applicantCopy.id, applicantCopy)).then((response) => {
      resetReferences()
      if (response.type === 'UPDATE_APPLICANT_SUCCESS') {
        setShowPortfolioInput(false)
        setPortfolioType('url')
        manageApplicantData(response.payload.data.data)
        resetStates()
      } else {
        setShowPortfolioInput(false)
        setLoading(false)
        resetStates()
      }
    })
  }

  const resetReferences = () => {
    portfolioToDelete.current = null
  }

  const handleOpenModal = (index, type) => {
    resetReferences()
    portfolioToDelete.current = index
    setPortfolioType(type)
    setShowModal(true)
  }

  const onModalCancel = async () => {
    resetReferences()
    setShowModal(false)
  }

  const handleOpenLink = (e, urlString) => {
    e.preventDefault()
    e.stopPropagation()
    if (urlString) {
      if (checkHttp(urlString)) {
        window.open(urlString, '_blank')
      } else window.open('https://' + urlString, '_blank')
    }
  }

  const checkHttp = (urlString) => {
    if (urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0) {
      return true
    } else return false
  }

  return (
    <>
      <div className="candidate__modalTabCont candidate__modalTabCont-top candidate__modalTabCont-active" id="tabCont3">
        <div className="candidate__modalTabContInner candidate__modalTabContInner-w582 candidate__modalTabContInner-top">
          <p className="candidate__modalTabContTitle">{t('candidate.resume')}</p>
          <div className="candidate__modalTabContPortArea">
            <a
              href={applicant.resume_url}
              target="_blank"
              rel="noopener noreferrer"
              className="recommendation__ddFile recommendation__ddFile-pdf"
            >
              {replaceFileName(applicant?.resume_url)}
            </a>
          </div>
          <div className="candidate__modalTabContInner candidate__modalTabContInner-w582">
            <p className="candidate__modalTabContTitle">{t('candidate.cv')}</p>
            <div className="candidate__modalTabContPortArea">
              <a
                href={applicant.work_history_cv_url}
                target="_blank"
                rel="noopener noreferrer"
                className="recommendation__ddFile recommendation__ddFile-pdf"
              >
                {replaceFileName(applicant?.work_history_cv)}
              </a>
            </div>
          </div>
          <div className="candidate__modalTabContInner candidate__modalTabContInner-w582">
            <p className="candidate__modalTabContTitle">
              {t('candidate.otherDocs')}&nbsp;&nbsp;&nbsp;&nbsp;
              {otherDocsUrlDisplay?.length}
              {t('candidateSlideout.item')}&nbsp;&nbsp;
              {showCircular || (loading && <CircularProgress color="primary" size="1rem" />)}
            </p>
            {otherDocsUrlDisplay?.map((item, index) => (
              <CandidatePortfolioUrlItem
                key={index}
                index={index}
                item={item}
                handleOpenLink={handleOpenLink}
                handleOpenModal={handleOpenModal}
              />
            ))}
            {otherDocsPdfDisplay?.map((item, index) => (
              <CandidatePortfolioPdfItem
                key={index}
                index={index}
                otherDocsPdf={otherDocsPdf.current}
                handleOpenLink={handleOpenLink}
                item={item}
                handleOpenModal={handleOpenModal}
              />
            ))}
            {showPortfolioInput && (
              <CandidatePortfolioInput
                handleRadioClick={handleRadioClick}
                portfolioType={portfolioType}
                pdfToUpload={pdfToUpload}
                portfolioUrl={portfolioUrl}
                setPortfolioUrl={setPortfolioUrl}
                handleFileInput={handleFileInput}
                handleRemoveFile={handleRemoveFile}
                loading={loading}
                checkIfFilled={checkIfFilled}
                handleSubmit={handleSubmit}
              />
            )}
            {!showPortfolioInput && otherDocsUrlDisplay?.length < 10 && !loading && (
              <Button
                modifier="iconL"
                id="candidatePortfolioPlus"
                className="candidate__modalTabContPortBtnArea"
                variant={!loading ? 'shadow' : 'yellowDessable'}
                disabled={loading}
                onClick={() => {
                  setShowPortfolioInput(true)
                  setPortfolioUrl('')
                }}
              >
                <span className="btn__btnIcon">
                  <PlusIcon />
                </span>
                {t('hints.addPortfolio')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal open={showModal} onClose={onModalCancel} containerType="contA">
        <ConfirmModalContent
          title={t('candidateSlideout.deletePortfolio')}
          text={t('candidateSlideout.deleteWarning')}
          onClick={() => setShowModal(false)}
          buttons={[
            {
              type: 'cancel',
              text: t('common.cancel'),
              props: { modifier: 'spW100A', className: 'btn__outlineBtn modalClose onlyModalClose withoutScroll' },
            },
            {
              type: 'confirm',
              text: t('common.delete'),
              onClick: () => handleSubmit('delete'),
              props: { modifier: 'b', disabled: loading },
            },
          ]}
        />
      </Modal>
    </>
  )
}

CandidateResumePortfolioTab.propTypes = {
  applicantId: PropTypes.number,
}

export default CandidateResumePortfolioTab
