import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

const PersonInCharge = (props) => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('')

  const { adInchargeList, handleSelectPerInCharge, showPersonIncharge, handleOnClose } = props

  const [showAdList, setShowAdList] = useState(showPersonIncharge)
  const { register } = useForm({})

  const selectAdIncharge = (e, row) => {
    e.preventDefault()
    setShowAdList(false)
    handleSelectPerInCharge(e, row)
  }

  const handleContactSearch = (e) => {
    e.preventDefault()
    const { value } = e.target
    setKeyword(value)
    setShowAdList(true)
  }

  const handleClose = (e) => {
    setShowAdList(false)
    handleOnClose(e)
  }

  return (
    <>
      {showAdList ? (
        <div className="form__dropInputArea form__dropInputArea-active" id="telAreaWrap">
          <div className="form__dropInputAreaBack" onClick={handleClose}></div>
          <div className="form__dropInputAreaInner" id="telArea">
            <div className="form__dropSearchArea">
              <p className="form__dropSearchText">{t('fieldLabels.personInchargeNumber')}</p>
              <input
                type="text"
                {...register('contact_search')}
                id="telSearch"
                placeholder={t('hints.narrowDown')}
                className="form__dropSearch"
                onChange={handleContactSearch}
              />
            </div>
            <ul className="form__dropUl">
              {keyword !== ''
                ? adInchargeList
                    ?.filter((contact) => {
                      return (
                        keyword.trim().length === 0 ||
                        (contact.full_name.includes(keyword) &&
                          contact.full_name !== ' ' &&
                          contact.status['id'] == 1 &&
                          !isEmpty(contact.contact_number))
                      )
                    })
                    .map((row, index) => {
                      return (
                        <li className="form__dropLi form__dropLi" key={index}>
                          <p className="form__dropName">{row.full_name}</p>
                          <p className="form__dropTelNum">{row.contact_number}</p>
                          <div className="form__dropBtnWrap">
                            <button
                              className="btn btn__shadowBtn form__dropBtn"
                              data-nametel={row.contact_number}
                              onClick={(e) => selectAdIncharge(e, row)}
                            >
                              {t('buttonLabels.select')}
                            </button>
                          </div>
                        </li>
                      )
                    })
                : adInchargeList
                    ?.filter(
                      (data) => data.full_name !== ' ' && data.status['id'] == 1 && !isEmpty(data.contact_number)
                    )
                    .map((row, index) => {
                      return (
                        <li className="form__dropLi form__dropLi" key={index}>
                          <p className="form__dropName">{row.full_name}</p>
                          <p className="form__dropTelNum">{row.contact_number}</p>
                          <div className="form__dropBtnWrap">
                            <button
                              className="btn btn__shadowBtn form__dropBtn"
                              data-nametel={row.contact_number}
                              onClick={(e) => selectAdIncharge(e, row)}
                            >
                              {t('buttonLabels.select')}
                            </button>
                          </div>
                        </li>
                      )
                    })}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  )
}

PersonInCharge.propTypes = {
  showPersonIncharge: PropTypes.bool,
  handleSelectPerInCharge: PropTypes.func,
  handleOnClose: PropTypes.func,
  adInchargeList: PropTypes.array,
}

export default PersonInCharge
