import { getApplicantsList } from 'services/applicant'
import { createApplicantsNote, deleteApplicantNotes, updateApplicantNotes } from 'services/applicantsNote'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as types from './actionTypes'

const initialState = {
  files: null,
  pending: false,
  updated: false,
  success: false,
  error: null,
  applicantslist: [],
  details: null,
  applicants: [],
  applicantStatus: [],
  params: {
    limit: 10,
    page: 1,
    keyword: null,
    status: null,
    ad_person_incharge: null,
    applicant: null,
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_FILE_REQUEST:
      return {
        ...state,
        files: null,
        pending: true,
        success: false,
      }
    case types.GET_FILE_SUCCESS:
      return {
        ...state,
        files: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_FILE_FAILURE:
      return {
        ...state,
        files: null,
        pending: false,
        success: false,
        error: payload.error,
      }

    //applicants list
    case types.GET_APPLICANTS_LIST_REQUEST:
      return {
        ...state,
        applicantslist: null,
        pending: true,
        success: false,
      }
    case types.GET_APPLICANTS_LIST_SUCCESS:
      return {
        ...state,
        applicantslist: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_APPLICANTS_LIST_FAILURE:
      return {
        ...state,
        applicantslist: null,
        pending: false,
        success: false,
        error: payload.error,
      }

    //applicant details
    case types.GET_APPLICANT_DETAILS_REQUEST:
      return {
        ...state,
        details: null,
        pending: true,
        success: false,
      }
    case types.GET_APPLICANT_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_APPLICANT_DETAILS_FAILURE:
      return {
        ...state,
        details: null,
        pending: false,
        success: false,
        error: payload.error,
      }

    //create applicant
    case types.CREATE_APPLICANT_REQUEST:
      return {
        ...state,
        details: null,
        pending: true,
        success: false,
      }
    case types.CREATE_APPLICANT_SUCCESS:
      return {
        ...state,
        details: payload.data,
        pending: false,
        success: true,
      }
    case types.CREATE_APPLICANT_FAILURE:
      return {
        ...state,
        details: null,
        pending: false,
        success: false,
        error: payload.error,
      }

    //update applicant
    case types.UPDATE_APPLICANT_REQUEST:
      return {
        ...state,
        details: null,
        pending: true,
        success: false,
      }
    case types.UPDATE_APPLICANT_SUCCESS:
      return {
        ...state,
        details: payload.data,
        pending: false,
        success: true,
      }
    case types.UPDATE_APPLICANT_FAILURE:
      return {
        ...state,
        details: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case getApplicantsList.pending:
      return {
        ...state,
        applicants: null,
        pending: true,
        success: false,
      }
    case getApplicantsList.fulfilled:
      return {
        ...state,
        applicants: payload.data,
        pending: false,
        success: true,
        pagination: {
          total: payload.meta.total,
          currentPage: payload.meta.currentPage,
          lastPage: payload.meta.lastPage,
        },
      }
    case getApplicantsList.failure:
      return {
        ...state,
        applicants: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case types.UPDATE_APPLICANTS_SEARCH_PARAMS:
      return {
        ...state,
        params: payload,
      }
    case createApplicantsNote.fulfilled:
    case deleteApplicantNotes.fulfilled:
    case updateApplicantNotes.fulfilled:
      return updateApplicant(state, payload.data.applicant)

    case AUTH_SIGNOUT:
      return initialState
    default:
      return state
  }
}

function updateApplicant(state, applicant) {
  const tmp = state.applicants.slice()
  const index = tmp.findIndex((item) => item.id === applicant.id)

  if (index !== -1) {
    tmp[index] = applicant
  }

  return {
    ...state,
    applicants: tmp,
  }
}
