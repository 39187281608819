import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import SelectionManagementCard from '../SelectionManagementCard'
import PropTypes from 'prop-types'
import { SELECTION_STATUSES_LIST } from 'utils/constants'
import { actionCloseRPSlideout, actionTriggerLastChanged } from 'store/referral-proposals/actionCreators'
import { SelectionModal } from 'components'

function SelectionManagementList({
  items,
  state,
  active,
  selectionStatusFilter,
  openSelectionManagementCard,
  setOpenSelectionManagementCard,
  setCloseSelectionManagementCard,
  setProposalIsUpdated,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const total = useSelector((state) => state.referralProposals.pagination.total)
  const [results, setResults] = useState(items)
  const [activeSideTabs, setActiveSideTabs] = useState()

  useEffect(() => {
    setResults(items)
  }, [items])

  useEffect(() => {
    let selectionStatusActiveItems = []

    if (selectionStatusFilter.length !== 0) {
      Object.keys(selectionStatusFilter).map((key) => {
        selectionStatusFilter[key]['value'] === 'under_selection' ||
        selectionStatusFilter[key]['value'] === 'decline_or_cancel'
          ? selectionStatusActiveItems.push(t(`selection.sideMenu.${selectionStatusFilter[key]['value']}`))
          : selectionStatusActiveItems.push(
              t(`selection.sideMenu.${SELECTION_STATUSES_LIST[selectionStatusFilter[key]['value']]}`)
            )
      })
      setActiveSideTabs(selectionStatusActiveItems)
    }
  }, [selectionStatusFilter])

  useEffect(() => {
    if (active) {
      setActiveSideTabs([t(`selection.sideMenu.${active}`)])
    }
  }, [active])

  const onUpdate = () => dispatch(actionTriggerLastChanged(new Date()))
  const open = useSelector((state) => state.referralProposals.slideOut.open)

  const handleClose = () => {
    dispatch(actionCloseRPSlideout())
  }

  return (
    <>
      {results.length > 0 ? (
        <>
          <h3 className="selection__mainTitle">
            {'「 ' + activeSideTabs?.join('、') + ' 」'}
            {t('selectionManagement.list_of_selections_for_nominations')}
            <span className="selection__mainTitle-num">{total}</span>
            <span className="selection__mainTitle-unit">{t('selectionManagement.unit')}</span>
          </h3>

          <SelectionManagementCard
            items={results}
            state={state}
            open={openSelectionManagementCard}
            setOpen={setOpenSelectionManagementCard}
            handleClose={setCloseSelectionManagementCard}
            setProposalIsUpdated={setProposalIsUpdated}
          />
        </>
      ) : (
        <>
          <h3 className="selection__mainTitle">{t('selectionManagementSearch.listRetrievalUnsuccesfulTitle')}</h3>
          <p className="selection__mainNoneText">{t('selectionManagementSearch.listRetrievalUnsuccesfulMessage')}</p>
        </>
      )}
      <SelectionModal onClose={handleClose} open={open} setProposalIsUpdated={onUpdate} />
    </>
  )
}

const IDType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

SelectionManagementList.propTypes = {
  active: IDType,
  items: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  selectionStatusFilter: PropTypes.any,
  openSelectionManagementCard: PropTypes.bool,
  setOpenSelectionManagementCard: PropTypes.func,
  setCloseSelectionManagementCard: PropTypes.func,
  setProposalIsUpdated: PropTypes.func,
}

export default SelectionManagementList
