import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Box = ({ children }) => <div className="log__mainLiInner">{children}</div>

Box.propTypes = {
  children: PropTypes.node,
}

const ActionDate = ({ date }) => <div className="log__mainDate">{date}</div>

ActionDate.propTypes = {
  date: PropTypes.string.isRequired,
}

const CompanyDetails = ({ name, id, label }) => (
  <div className="log__mainCompanyName">
    <span className="log__mainCompanyName-txt">{name}</span>
    <span className="log__mainCompanyName-gray">
      {label}: {id}
    </span>
  </div>
)

CompanyDetails.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const AccountType = ({ accountType }) => <div className="log__mainCategory">{accountType}</div>

AccountType.propTypes = {
  accountType: PropTypes.string.isRequired,
}

const AccountDetails = ({ accountName, accountLevel, label }) => (
  <div className="log__mainAccount">
    <span className="log__mainAccount-txt">{accountName}</span>
    <span className="log__mainAccount-gray">
      {label}: {accountLevel}
    </span>
  </div>
)

AccountDetails.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountLevel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const Action = ({ action }) => <div className="log__mainContent">{action}</div>

Action.propTypes = {
  action: PropTypes.string,
}

const JobDetails = ({ jobPostURL, jobPostTitle, jobPostID, label }) => (
  <div className="log__mainRecruit">
    <span className="log__mainRecruit-txt">
      <Link to={jobPostURL}>{jobPostTitle}</Link>
    </span>
    {jobPostID && (
      <span className="log__mainRecruit-gray">
        {label}: {jobPostID}
      </span>
    )}
  </div>
)

JobDetails.propTypes = {
  jobPostURL: PropTypes.string,
  jobPostTitle: PropTypes.string,
  jobPostID: PropTypes.string,
  label: PropTypes.string.isRequired,
}

Box.ActionDate = ActionDate
Box.CompanyDetails = CompanyDetails
Box.AccountType = AccountType
Box.AccountDetails = AccountDetails
Box.Action = Action
Box.JobDetails = JobDetails
export default Box
