import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { COMPANY_STATUSES, USER_STATUSES } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser, searchUser } from 'services/users'
import { getUser } from 'services/auth'
import { showNotification } from 'store/notification/actionCreators'
import { selectAuthUser } from 'store/auth/selectors'
import DotIcon from 'assets/img/threedot.svg'
import { FormGroupCheck, Modal } from 'components/molecules'
import { Button, Radio } from 'components/atoms'
import clsx from 'clsx'
import { accountType, isParent } from 'utils/helper'
import { useHistory } from 'react-router-dom'

function determineItems(user, t) {
  const statusId = user.status.id

  switch (statusId) {
    case USER_STATUSES.active:
      return [
        { id: USER_STATUSES.suspended, label: t('userStatus.suspended'), supLabel: t('userAccount.suspendedSupLbl') },
        { id: USER_STATUSES.archived, label: t('userStatus.archived'), supLabel: t('userAccount.archiveSupLbl') },
      ]
    case USER_STATUSES.suspended:
      return [
        { id: USER_STATUSES.active, label: t('userStatus.active'), supLabel: t('userAccount.activeSupLbl') },
        { id: USER_STATUSES.archived, label: t('userStatus.archived'), supLabel: t('userAccount.archiveSupLbl') },
      ]
    case USER_STATUSES.archived:
      return [
        { id: USER_STATUSES.active, label: t('userStatus.active'), supLabel: t('userAccount.activeSupLbl') },
        { id: USER_STATUSES.suspended, label: t('userStatus.suspended'), supLabel: t('userAccount.suspendedSupLbl') },
      ]
    default:
      return []
  }
}

function AccountManagementActionCell({ user }) {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const authUser = useSelector(selectAuthUser)
  const [showStatus, setShowStatus] = useState(false)
  const [showPermission, setShowSPermission] = useState(false)
  const [selectedStatusId, setSelectedStatusId] = useState(0)
  const [selectedPermissionId, setSelectedPermissionId] = useState('')
  const parentUsersCount = useSelector((state) => state.users.listMeta.parentUsersCount)
  const isSelf = authUser.id === user.id
  const isHR = accountType(authUser) === 'HR'
  const disabledStatus = selectedStatusId >= 1 ? true : false
  const disabledPermission = selectedPermissionId !== '' ? true : false
  const userCompanyStatus = authUser.company.status

  const handleClose = () => {
    setShow(false)
    setShowStatus(false)
    setShowSPermission(false)
  }

  const items = determineItems(user, t)

  const handleItemClick = async () => {
    let params = {}
    if (selectedStatusId >= 1) {
      params = {
        user_status_id: selectedStatusId,
      }
    } else {
      params = {
        permission: selectedPermissionId,
      }
    }
    await dispatch(updateUser(user.id, { params }))
      .then(() => {
        // If the current user has been demoted to child then
        // It no longer has the permission for this page
        // So we redirect it to homepage
        if (isSelf && selectedPermissionId === 'child') {
          // Update current user and then redirect to homepage
          dispatch(getUser()).then(() => history.replace('/'))
        }

        dispatch(searchUser())
        dispatch(
          showNotification(
            selectedStatusId > 0 ? t('userAccount.successStatusChange') : t('userAccount.successpermission'),
            { modifier: 'btm spBtnUp' }
          )
        )
      })
      .catch(({ error }) => {
        dispatch(showNotification(error, { type: 'danger', modifier: 'btm' }))
      })
    setShow(false)
    setShowStatus(false)
    setShowSPermission(false)
    setSelectedPermissionId('')
    setSelectedStatusId('')
  }

  const handleModalStatus = () => {
    if (userCompanyStatus !== COMPANY_STATUSES.active) return
    setShow(false)
    setShowStatus(true)
  }

  const handleModalPermission = () => {
    if (isParent(user) && parentUsersCount < 2) return
    setShow(false)
    setShowSPermission(true)
  }

  const handlePopModal = () => setShow(true)

  const options = [
    {
      id: 'parent',
      label: t('userPermission.parent'),
      supLabel: t('userAccount.permissionParentSupLbl'),
    },
    {
      id: 'child',
      label: t('userPermission.child'),
      supLabel: isHR ? t('userAccount.permissionChildSupLblHR') : t('userAccount.permissionChildSupLblAD'),
    },
  ]

  return (
    <>
      <p className={clsx('member__mainDot memberDot', show ? 'active' : '')} onClick={handlePopModal}>
        <img src={DotIcon} alt="" className="search__mainContFooterDotImg" width="20" height="8" />
      </p>

      <p
        className="member__mainDotText1"
        style={{ display: show && user.status.id !== USER_STATUSES.pending ? 'block' : 'none' }}
      >
        <span className="member__mainDotSpan" onClick={handleModalStatus}>
          {user.status.id === USER_STATUSES.pending ? '' : t('userAccount.changeStatusLbl')}
        </span>

        <span className="member__mainDotSpan" onClick={handleModalPermission}>
          {t('userAccount.permission')}
        </span>
      </p>

      <Modal containerType="contA" open={showStatus} onClose={handleClose}>
        <Modal.Header blockElClass="member__modalHeader">
          <Modal.Title>{t('userAccount.modalTitle')}</Modal.Title>
        </Modal.Header>

        <FormGroupCheck modifier="mgt24">
          {items.map((item) => (
            <FormGroupCheck.Item
              key={item.id}
              Input={Radio}
              inputProps={{
                disabled: isSelf,
                checked: item.id === selectedStatusId,
                onChange: () => setSelectedStatusId(item.id),
              }}
              label={item.label}
              labelProps={{
                end: <span className="form__checkLabel-gr"> {item.supLabel}</span>,
                modifier: 'ib',
              }}
              type="round"
            />
          ))}
        </FormGroupCheck>

        <Modal.Footer>
          <Button type="button" blockElClass="btn btn-mgr btn__outlineDissable" onClick={() => setShowStatus(false)}>
            {t('userAccount.cancelInviteBtn')}
          </Button>
          <Button
            type="submit"
            variant="yellowDessable"
            variantMod={{ active: disabledStatus }}
            disabled={!disabledStatus}
            onClick={handleItemClick}
          >
            {t('userAccount.changeStatusBtn')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal containerType="contA" open={showPermission} onClose={handleClose}>
        <Modal.Header blockElClass="member__modalHeader">
          <Modal.Title>{t('userAccount.modalTitle')}</Modal.Title>
        </Modal.Header>

        <FormGroupCheck modifier="mgt24">
          {options.map((item) => (
            <FormGroupCheck.Item
              key={item.id}
              Input={Radio}
              inputProps={{
                checked: item.id === selectedPermissionId,
                onChange: () => setSelectedPermissionId(item.id),
              }}
              label={item.label}
              labelProps={{
                end: <span className="form__checkLabel-gr"> {item.supLabel}</span>,
                modifier: 'ib',
              }}
              type="round"
            />
          ))}
        </FormGroupCheck>

        <Modal.Footer>
          <Button
            type="button"
            blockElClass="btn btn-mgr btn__outlineDissable"
            onClick={() => setShowSPermission(false)}
          >
            {t('userAccount.cancelInviteBtn')}
          </Button>
          <Button
            type="submit"
            variant="yellowDessable"
            variantMod={{ active: disabledPermission }}
            onClick={handleItemClick}
            disabled={!disabledPermission}
          >
            {t('userAccount.changeStatusBtn')}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="member__mainDotBg" style={{ display: show ? 'block' : '' }} onClick={() => setShow(false)}></div>
    </>
  )
}

AccountManagementActionCell.propTypes = {
  user: PropTypes.object.isRequired,
}

export default AccountManagementActionCell
