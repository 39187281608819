import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function SearchConditionGroup({ label, handleModalOpen }) {
  const { t } = useTranslation()
  return (
    <>
      <div className="search__conditonTitleArea">
        <p className="search__conditonTitle">{label}</p>
        <p
          className="search__conditonBtn popModal"
          onClick={handleModalOpen}
          data-modal="modal"
          data-modalcont="modalCont3"
        >
          {t('jobPosting.search.select')}
        </p>
      </div>
      <div
        className="search__conditonTitleAreaSP popModal"
        id="searchIndustrySp"
        data-modal="modal"
        data-modalcont="modalCont3sp"
      >
        <p className="search__conditonTitle">{label}</p>
        <div className="search__modalTitleNum">
          <span className="search__modalTitleNum-span">0</span>
        </div>
      </div>
    </>
  )
}

SearchConditionGroup.propTypes = {
  label: PropTypes.string,
  handleModalOpen: PropTypes.func,
}

export default SearchConditionGroup
