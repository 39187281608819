import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LoginLogo from 'assets/img/logo-login.svg'
import { Button, Input } from 'components/atoms'
import { getHomePage } from 'utils/helper'
import { selectAuthUser } from 'store/auth/selectors'
import { signInUser, googleRedirect } from 'services/auth'
import { showNotification } from 'store/notification/actionCreators'
import GoogleIcon from 'assets/img/google-icon.webp'

// We may not need this if username and password is really not needed anymore
const USE_GOOGLE_AUTH = false

function PasswordStrategyForm() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const user = useSelector(selectAuthUser)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues: { username: '', password: '' } })
  const { t } = useTranslation()
  const error = useSelector((state) => state.auth.error)

  const validationRules = {
    username: {
      required: {
        value: String,
        message: t('auth.required'),
      },
      pattern: {
        value: /\S+@\S+\.\S+/, // Regex Email Validation
        message: t('auth.email'),
      },
    },
    password: {
      required: {
        value: String,
        message: t('auth.required'),
      },
    },
  }

  const onsSubmit = async (data) => {
    await dispatch(signInUser(data))
  }

  useEffect(() => {
    if (error?.message) {
      dispatch(showNotification(error.message, { type: 'danger', autoClose: 10000 }))
    }
  }, [error])

  if (auth.isAuthenticated && user) {
    return <Redirect to={getHomePage(user)} />
  }

  return (
    <form onSubmit={handleSubmit(onsSubmit)}>
      <div className="adminLogin__logo">
        <img src={LoginLogo} alt="HuuNo" />
      </div>
      <div className="adminLogin__inputWrap">
        <p className="adminLogin__inputTtl">{t('accountManagement.emailLabel')}</p>
        <Input {...register('username', validationRules.username)} />
        <p className="adminLogin__inputTtl">{t('common.password')}</p>
        <Input type="password" {...register('password', validationRules.password)} />
      </div>

      <div className="adminLogin__btnWrap">
        <Button type="submit" variant={isSubmitting ? 'shadowDissable' : 'shadow'} disabled={isSubmitting}>
          {t('common.login')}
        </Button>

        <p className="pwForget">
          <Link to="/forgot-password">{t('forgot.forgotLink')}</Link>
        </p>
      </div>
    </form>
  )
}

function GoogleAuthStrategyForm() {
  const { t } = useTranslation()
  const [fetching, setFetching] = useState(false)
  const dispatch = useDispatch()

  const handleClick = async () => {
    setFetching(true)
    await dispatch(googleRedirect()).then(() => {
      setFetching(false)
    })
  }

  return (
    <>
      <div className="adminLogin__logo">
        <img src={LoginLogo} alt="HuuNo" />
      </div>

      <p className="mgb">{t('common.loginUsingGmailAccount')}</p>

      <div className="adminLogin__btnWrap">
        <Button
          disabled={fetching}
          modifier="spb"
          onClick={handleClick}
          variant={fetching ? 'shadowDissable' : 'shadow'}
        >
          <img className="mgr" src={GoogleIcon} width={25} height={25} /> <span>{t('common.loginWithGoogle')}</span>
        </Button>
      </div>
    </>
  )
}

function LoginForm() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const user = useSelector(selectAuthUser)
  const error = useSelector((state) => state.auth.error)

  useEffect(() => {
    if (error?.message) {
      dispatch(showNotification(error.message, { type: 'danger', autoClose: 10000 }))
    }
  }, [error])

  if (auth.isAuthenticated && user) {
    return <Redirect to={getHomePage(user)} />
  }

  return (
    <div className="adminLogin__mainWrap">
      {USE_GOOGLE_AUTH ? <GoogleAuthStrategyForm /> : <PasswordStrategyForm />}
    </div>
  )
}

export default LoginForm
