export const UPDATE_JOB_SEARCH_PARAMS = 'UPDATE_JOB_SEARCH_PARAMS'
export const UPDATE_JOB_POSTING_REQUEST = 'UPDATE_JOB_POSTING_REQUEST'
export const UPDATE_JOB_POSTING_SUCCESS = 'UPDATE_JOB_POSTING_SUCCESS'
export const UPDATE_JOB_POSTING_FAILURE = 'UPDATE_JOB_POSTING_FAILURE'
export const UPDATE_JOB_SEARCH_FILTERS = 'UPDATE_JOB_SEARCH_FILTERS'
export const RESET_JOB_SEARCH_FILTERS = 'RESET_JOB_SEARCH_FILTERS'
export const OPEN_PROPOSAL_SLIDEOUT = 'OPEN_PROPOSAL_SLIDEOUT'
export const CLOSE_PROPOSAL_SLIDEOUT = 'CLOSE_PROPOSAL_SLIDEOUT'
export const TRIGGER_JOB_SEARCH = 'TRIGGER_JOB_SEARCH'
export const UNTRIGGER_JOB_SEARCH = 'UNTRIGGER_JOB_SEARCH'
