export const UPDATE_APPLICANTS_SEARCH_PARAMS = 'UPDATE_APPLICANTS_SEARCH_PARAMS'

export const GET_FILE_REQUEST = 'GET_FILE_REQUEST'
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS'
export const GET_FILE_FAILURE = 'GET_FILE_FAILURE'

export const GET_APPLICANTS_LIST_REQUEST = 'GET_APPLICANTS_LIST_REQUEST'
export const GET_APPLICANTS_LIST_SUCCESS = 'GET_APPLICANTS_LIST_SUCCESS'
export const GET_APPLICANTS_LIST_FAILURE = 'GET_APPLICANTS_LIST_FAILURE'

export const GET_APPLICANT_DETAILS_REQUEST = 'GET_APPLICANT_DETAILS_REQUEST'
export const GET_APPLICANT_DETAILS_SUCCESS = 'GET_APPLICANT_DETAILS_SUCCESS'
export const GET_APPLICANT_DETAILS_FAILURE = 'GET_APPLICANT_DETAILS_FAILURE'

export const UPDATE_APPLICANT_REQUEST = 'UPDATE_APPLICANT_REQUEST'
export const UPDATE_APPLICANT_SUCCESS = 'UPDATE_APPLICANT_SUCCESS'
export const UPDATE_APPLICANT_FAILURE = 'UPDATE_APPLICANT_FAILURE'

export const CREATE_APPLICANT_REQUEST = 'CREATE_APPLICANT_REQUEST'
export const CREATE_APPLICANT_SUCCESS = 'CREATE_APPLICANT_SUCCESS'
export const CREATE_APPLICANT_FAILURE = 'CREATE_APPLICANT_FAILURE'
