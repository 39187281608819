import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import { calculateModifiers } from 'utils/helper'

const Header = React.forwardRef(({ children, modifier }, ref) => {
  return (
    <div ref={ref} className={clsx('header', calculateModifiers('header', modifier))}>
      {children}
    </div>
  )
})

Header.displayName = 'Header'

Header.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
}

export default Header
