import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { selectJobPosting } from 'store/job-postings/selectors'
import { canPropose, fetchForEditProposal } from 'services/jobPostings'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType, inAccountType } from 'utils/helper'
import Buttons from './Buttons'
import { USER_TYPE_KEYS } from 'utils/constants'

function JobDetailFooter(props) {
  const jobPosting = useSelector(selectJobPosting)
  const { openedJobDetailFooter } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector(selectAuthUser)
  const account_type_AD2 = accountType(user) === 'AD2'

  useEffect(() => {
    if (jobPosting?.id && account_type_AD2) {
      dispatch(canPropose(jobPosting?.id))
    }
  }, [jobPosting?.id])

  useEffect(() => {
    const { editProposal } = new qs.parse(location.search)

    if (Number.isFinite(parseInt(editProposal))) {
      dispatch(fetchForEditProposal(editProposal))
    }
  }, [location.search])

  if (jobPosting === null) return <></>

  const isHuunoAdmin = inAccountType(user, USER_TYPE_KEYS.ADMIN)

  return (
    <>
      <footer
        className={clsx({
          ['footer footer-flex']: !openedJobDetailFooter,
        })}
      >
        {!openedJobDetailFooter && (
          <div className="footer__leftCont">
            <p className="footer__corp">{jobPosting.company_name}</p>
            <p className="footer__corp2">{jobPosting.job_title}</p>
          </div>
        )}

        {!isHuunoAdmin && <Buttons jobPosting={jobPosting} />}
      </footer>
    </>
  )
}

JobDetailFooter.propTypes = {
  id: PropTypes.any,
  openedJobDetailFooter: PropTypes.any,
}

export default JobDetailFooter
