import React from 'react'

import PropTypes from 'prop-types'

const BoxTitle = ({ title }) => <p className="selection__searchTitle">{title}</p>

BoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

const BoxFreeWordSearch = ({ freewordLabel, children }) => (
  <div className="selection__searchGroup">
    <label htmlFor="" className="selection__searchLabel">
      {freewordLabel}
    </label>
    {children}
  </div>
)

BoxFreeWordSearch.propTypes = {
  freewordLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const BoxDropdownArea = ({ children }) => <div className="selection__searchFlex">{children}</div>

BoxDropdownArea.propTypes = {
  children: PropTypes.node,
}

const BoxDropDown = ({ dropdownLabel, children }) => (
  <div className="selection__searchGroup">
    <div className="form__customSelectArea form__customSelectArea-2">
      <div className="form__customSelectTitle">{dropdownLabel}</div>
      <div className="form__customSelectWrap">{children}</div>
      <div className="form__customSelectBack" style={{ display: 'none' }}></div>
    </div>
  </div>
)

BoxDropDown.propTypes = {
  dropdownLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const BoxButtonArea = ({ children }) => <div className="selection__searchAreaBottom">{children}</div>

BoxButtonArea.propTypes = {
  children: PropTypes.node,
}

const BoxTop = ({ children }) => <div className="selection__searchAreaTop">{children}</div>

BoxTop.propTypes = {
  children: PropTypes.node,
}

const Box = ({ children }) => <div className="selection__searchArea">{children}</div>

Box.propTypes = {
  children: PropTypes.node,
}

Box.Title = BoxTitle
Box.FreeWordSearch = BoxFreeWordSearch
Box.DropdownArea = BoxDropdownArea
Box.DropDown = BoxDropDown
Box.ButtonArea = BoxButtonArea
Box.Top = BoxTop

export default Box
