import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FormGroupCheck } from 'components/molecules'
import { Checkbox } from 'components/atoms'

import { useFormContext } from 'react-hook-form'
function JobSearchModalTab({ lists, onHandleActiveList, activeList, onHandleCheck, onHandleCheckAll, onIsChecked }) {
  const jobFilters = useSelector((state) => state.jobPostings.jobPostingSearch.filters)
  const activeModal = useSelector((state) => state.jobPostings.jobPostingSearch.activeModal)
  const { t } = useTranslation()
  const { register } = useFormContext()
  useEffect(() => {}, [lists])

  const count = (index) => {
    let count = 0
    count =
      typeof jobFilters[activeModal] === 'object'
        ? jobFilters[activeModal][index]?.length
        : JSON.parse(jobFilters[activeModal])[index]?.length
    return count
  }

  const listLength = (list, index) =>
    list.children ? list.children.length === count(index) : list.descendants.length === count(index)

  return (
    <div className="modal__contMain modal__contMain-flex">
      <div className="modal__contMainLeft">
        <ul className="modal__contMainLeftUl">
          {lists.map((list, index) => (
            <li
              className={`modal__contMainLeftLi modal__contMainLeftLi-${activeList === index ? 'active' : ''}`}
              key={list.id}
              onClick={() => onHandleActiveList(index)}
            >
              {list.name}
              <span className="modal__contMainLeftLi-num" style={{ display: count(list.id) > 0 ? 'flex' : 'none' }}>
                {count(list.id)}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="modal__contMainRight">
        {lists.map((list, index) => (
          <div
            key={index}
            className={`modal__contMainRightCont modal__contMainRightCont-${activeList === index ? 'active' : ''}`}
          >
            <span className="form__groupCheckItem form__groupCheckItem-mgb16">
              <FormGroupCheck.Item
                Input={Checkbox}
                inputProps={{
                  value: list.id,
                  onChange: onHandleCheckAll,
                  blockElClass: `form__checkItemModal ${listLength(list, list.id) ? 'checked' : ''}`,
                  name: list.title,
                  defaultChecked: listLength(list, list.id),
                }}
                label={t(`jobPosting.search.modal.selectAll`)}
                type="square"
              />
            </span>
            <div className="modal__contMainRightInner">
              {'children' in list
                ? list.children.map((option, idx) => (
                    <FormGroupCheck.Item
                      key={idx}
                      Input={Checkbox}
                      inputProps={{
                        ...register(`${activeModal}.${index}.${idx}`),
                        value: option.id,
                        onChange: onHandleCheck,
                        name: activeModal,
                        ['data-index']: list.id,
                        blockElClass: `form__checkItemModal ${onIsChecked(list.id, option.id) ? 'checked' : ''}`,
                        defaultChecked: onIsChecked(list.id, option.id),
                      }}
                      label={option.name}
                      type="square"
                    />
                  ))
                : list.descendants.map((option, idx2) => (
                    <FormGroupCheck.Item
                      key={idx2}
                      Input={Checkbox}
                      inputProps={{
                        ...register(`${activeModal}.${index}.${idx2}`),
                        value: option.id,
                        onChange: onHandleCheck,
                        name: activeModal,
                        ['data-index']: list.id,
                        blockElClass: `form__checkItemModal ${onIsChecked(list.id, option.id) ? 'checked' : ''}`,
                        defaultChecked: onIsChecked(list.id, option.id),
                      }}
                      label={option.name}
                      type="square"
                    />
                  ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

JobSearchModalTab.propTypes = {
  lists: PropTypes.array,
  onHandleActiveList: PropTypes.func,
  activeList: PropTypes.number,
  onHandleCheck: PropTypes.func,
  onHandleCheckAll: PropTypes.func,
  onIsChecked: PropTypes.func,
}

export default JobSearchModalTab
