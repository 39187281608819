import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import some from 'lodash/some'

import { Button } from 'components/atoms'
import { selectAuthUser } from 'store/auth/selectors'
import { actionOpenEvaluationForm, actionSetEvaluationFormData } from 'store/evaluation-form/actionCreators'
import { accountType } from 'utils/helper'

function Content({ title, lineOne, lineTwo, buttonText, onClick, disabled }) {
  return (
    <>
      <p className="selectionModal__tabContFinishTitle">{title}</p>
      <p className="selectionModal__tabContFinishText">
        {lineOne}
        <br />
        {lineTwo}
      </p>
      <Button component="a" onClick={onClick} blockElClass="selectionModal__tabContFinishBtn" modifier={{ disabled }}>
        {buttonText}
      </Button>
    </>
  )
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  lineOne: PropTypes.string,
  lineTwo: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const EvaluationItem = ({ referralProposal, message }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'selection.modal.evaluation' })
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()
  const userAccountType = accountType(user)
  const open = useSelector((state) => state.evaluationForm.open)
  const success = useSelector((state) => state.evaluationForm.success)
  const participants = message.meta.participants || []
  const wasSuccessful = !open && success
  const isReferral = referralProposal.type === 'referral'
  const disabled =
    !participants.includes(user.id) || // Is not a particpant
    some(message.meta.evaluations || [], ['user_id', user.id]) || // Has evaluaated
    open || // Is evaluating
    wasSuccessful // Is evaluated successfully

  const handleClick = (e) => {
    e.preventDefault()
    if (disabled) return

    const toEvaluate = participants.filter((id) => +id !== user.id)[0]

    dispatch(
      actionSetEvaluationFormData({
        evaluated_id: toEvaluate,
        job_posting_id: referralProposal.job_posting.id,
        referral_proposal_id: referralProposal.id,
      })
    )
    dispatch(actionOpenEvaluationForm())
  }

  return (
    <div className="selectionModal__tabContFinish selectionModal__tabContCommentArea-last">
      {userAccountType === 'AD1' && (
        <Content
          title={t('title')}
          onClick={handleClick}
          disabled={disabled}
          lineOne={t('message', { applicant: referralProposal.job_applicant?.fullName })}
          lineTwo={t('message2', { company: referralProposal.job_posting.company_name })}
          buttonText={t('button', { company: referralProposal.job_posting.company_name })}
        />
      )}
      {userAccountType === 'AD2' && (
        <Content
          title={t('titleProposal')}
          onClick={handleClick}
          disabled={disabled}
          lineOne={t('messageV2', { name: referralProposal.referrer.full_name })}
          lineTwo={t('messageV2L2', { company: referralProposal.job_posting.company_name })}
          buttonText={t('button2', { company: referralProposal.job_posting.company_name })}
        />
      )}
      {/* HR Referral */}
      {userAccountType === 'HR' && isReferral && (
        <Content
          title={t('title2')}
          onClick={handleClick}
          disabled={disabled}
          lineOne={t('messageHR', { applicant: referralProposal.job_applicant?.fullName })}
          lineTwo={t('messageV2L2', { company: referralProposal.referrer?.company?.company_name })}
          buttonText={t('button2', { company: referralProposal.referrer?.company?.company_name })}
        />
      )}
      {/* HR Proposal */}
      {userAccountType === 'HR' && !isReferral && (
        <Content
          title={t('title')}
          onClick={handleClick}
          disabled={disabled}
          lineOne={t('messageV2', { name: referralProposal.referrer.full_name })}
          lineTwo={t('messageV2L2', { company: referralProposal.referrer?.company?.company_name })}
          buttonText={t('button2', { company: referralProposal.referrer?.company?.company_name })}
        />
      )}
    </div>
  )
}

EvaluationItem.propTypes = {
  referralProposal: PropTypes.object.isRequired,
  evalatee: PropTypes.object,
  message: PropTypes.object.isRequired,
}

export default EvaluationItem
