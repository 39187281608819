import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'components'
import { useForm } from 'react-hook-form'

const RadioItem = (props) => {
  const { register } = useForm()

  return (
    <div className="form__groupCheckAd">
      <Radio
        id={props.id}
        {...register(props.name)}
        name={props.name}
        value={props.value}
        defaultChecked={props.checked}
        onChange={props.onChange}
        className={props.className}
      />
      <label htmlFor={props.id} className="form__checkLabel">
        {props.label}
      </label>
    </div>
  )
}

RadioItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

export default RadioItem
