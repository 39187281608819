import React, { useRef, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import useResizeObserver from '@react-hook/resize-observer'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import find from 'lodash/find'
import { useAuth } from 'utils/hooks'

import NonAdminLogo from 'assets/img/logo.svg'
import AdminLogo from 'assets/img/logo-admin.svg'
import { NavMenuButton } from 'components/atoms'
import { CustomSelect, Header } from 'components/molecules'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType, isHuunoAdmin } from 'utils/helper'
import { actionSetHeaderSize } from 'store/app/actionCreators'
import { USER_STATUSES } from 'utils/constants'

const customSelectClasses = {
  selectArea: 'header__customSelectArea',
  selectWrap: 'header__customSelectWrap',
  input: 'header__customSelect',
  selectBack: 'header__customSelectBack',
  selectList: 'header__customSelectList header__customSelectList-right',
  selectItem: 'header__customSelectItem',
}

const Topbar = (props) => {
  const { userType } = props
  const authUser = useSelector(selectAuthUser)
  const isAdmin = isHuunoAdmin(authUser)
  const { t } = useTranslation()
  const { user, signOut } = useAuth()
  const account_type = accountType(authUser)
  const userStatus = authUser.status.id
  const { id } = useParams()
  //const history = useHistory()
  const profileURL = '/profile'
  const location = useLocation()
  const [isProfile, setIsProfile] = useState(false)

  const pages = [
    {
      title: t('header.navbar.home'),
      href: '/',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      exact: true,
    },
    {
      title: account_type === 'HR' ? t('header.navbar.jobPostings') : t('header.navbar.jobSearch'),
      href: account_type === 'HR' ? '/job-management' : '/jobSearch',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      exact: true,
    },
    {
      title: t('header.navbar.candidates'),
      href: '/applicants',
      access: ['ad1Parent', 'ad1Child'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: account_type === 'HR' ? t('header.navbar.selection') : t('header.navbar.recommendation'),
      href: '/selection-management',
      access: ['ad1Parent', 'ad1Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: t('header.navbar.proposal'),
      href: '/suggestion-management',
      access: ['ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: t('header.navbar.question'),
      href: '/questions',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
    },
    {
      title: t('header.navbar.favorite'),
      href: '/favorite',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child'],
    },

    // TODO: update href links once admin pages are finilized
    {
      title: t('header.navbar.companyAccount'),
      href: '/admin/company-management',
      access: ['admin'],
      exact: true,
    },
    {
      title: t('header.navbar.log'),
      href: '/admin/log',
      access: ['admin'],
    },
    {
      title: t('header.navbar.evaluation'),
      href: '/admin/evaluation',
      access: ['admin'],
    },
  ]

  const subPages = [
    {
      id: '/about-us',
      label: t('header.dropdown.companyInformation'),
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
    },
    {
      id: isAdmin ? '/admin/users/account-management' : '/users',
      label: t('header.dropdown.accountManagement'),
      access: ['ad1Parent', 'ad2Parent', 'hrParent'],
    },
    {
      id: '/profile',
      label: t('header.dropdown.profileManagement'),
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
    },
    {
      id: 'logout',
      label: t('header.dropdown.logout'),
      onClick: signOut,
      access: ['*'],
    },
  ]

  const handleSubMenuClick = (itemId) => {
    const item = find(subPages, { id: itemId })

    if (!item) return

    if (item.onClick) {
      item.onClick()
      return
    }
  }

  const userPages = () => {
    // Filter by userType
    const filterByUserType = pages.filter((page) => page.access.includes(userType))

    // Filter by userStatus
    return filterByUserType.filter((page) => !page.restrictStatus?.includes(userStatus))
  }

  const theSubPages = subPages.filter((page) => page.access.includes(userType) || page.access.includes('*'))

  const dispatch = useDispatch()

  const headerRef = useRef(null)
  useResizeObserver(headerRef, (e) => {
    const size = e.borderBoxSize[0]
    dispatch(
      actionSetHeaderSize({
        blockSize: size.blockSize,
        inlineSize: size.inlineSize,
      })
    )
  })

  const logo = isAdmin ? AdminLogo : NonAdminLogo

  useEffect(() => {
    const currentPage = location.pathname.replaceAll('/' + id, '')
    if (currentPage === profileURL) {
      if (typeof id !== 'undefined') {
        if (+id !== authUser.id) {
          setIsProfile(false)
        } else {
          setIsProfile(true)
        }
      } else {
        setIsProfile(true)
      }
    }
  }, [location.pathname])

  return (
    <Header ref={headerRef}>
      <div className="header__logoArea">
        <h1 className="header__logoWrap">
          <img src={logo} alt="HuuNo" className="header__logo" width="72" height="25"></img>
        </h1>
        <nav className="header__nav">
          <ul className="header__navUl">
            {userPages().map((page) => (
              <NavMenuButton key={page.title} {...page} />
            ))}
          </ul>
        </nav>
      </div>
      <div className="header__rightArea header__rightArea-aic">
        <CustomSelect
          classes={{
            ...customSelectClasses,
            input: isProfile ? 'header__customSelect header__customSelect-profileClicked' : 'header__customSelect',
          }}
          components={{
            input: ({ className, onClick }) => (
              <p className={className} onClick={onClick}>
                {user?.full_name}
              </p>
            ),
            selectItem: ({ option, ...props }) => (
              <li {...props}>
                {option.id === 'logout' ? (
                  <span className="header__customSelectItemLink">{option.label}</span>
                ) : (
                  <NavLink to={option.id} className="header__customSelectItemLink">
                    {option.label}
                  </NavLink>
                )}
              </li>
            ),
          }}
          options={theSubPages}
          onChange={handleSubMenuClick}
        />
      </div>
    </Header>
  )
}

Topbar.propTypes = {
  userType: PropTypes.string,
  user: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
