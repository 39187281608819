import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Button, Checkbox } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import ConsultantInCharge from 'components/organisms/common/ConsultantInCharge'
import { FormGroupCheck } from 'components/molecules'
import { USER_STATUSES } from 'utils/constants'

function ApplicantConsultantInChargeModal({ open, onClose, submitConsultantInCharge, isClear }) {
  const { t } = useTranslation()
  const defaultData = {
    ad_incharge: '',
  }
  const methods = useForm({ defaultValues: defaultData, mode: 'onChange' })
  const auth = useSelector((state) => state.auth.user?.data)
  const defaultAdInChargeList = auth.status.id === USER_STATUSES.active ? [auth.id] : []
  const [selectedAdInCharge, setSelectedAdInCharge] = useState(defaultAdInChargeList)
  const { register, handleSubmit, watch, setValue } = methods
  const users = useSelector((state) => state.adInCharge.adInchargelist?.data)
  const activeUsers = users?.filter((data) => data.full_name !== ' ' && data.status['id'] == USER_STATUSES.active)
  const onSubmit = async () => {
    submitConsultantInCharge(selectedAdInCharge)
    onClose()
    return
  }

  const isSelected = (value) => {
    const valueParse = parseInt(value)
    return selectedAdInCharge.includes(valueParse)
  }

  const handleClearSelection = () => {
    setSelectedAdInCharge([])
    setValue('selectAll', false)
  }
  const handleCheckAdInCharge = (e) => {
    const { value, checked } = e.target
    const parsedValue = parseInt(value)
    let selected = []
    if (checked) {
      if (!isSelected(value)) {
        selected = [...selectedAdInCharge, parsedValue]
      }
    } else {
      selected = selectedAdInCharge.filter((item) => item !== parsedValue)
      setValue('selectAll', false)
    }

    setSelectedAdInCharge(selected)
  }
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedAdInCharge(activeUsers.map((item) => parseInt(item.id)))
    } else {
      setSelectedAdInCharge([])
    }
  }
  const selectAll = watch('selectAll')
  const handleOnClose = (e) => {
    onClose(e)
  }

  useEffect(() => {
    if (isClear) {
      setSelectedAdInCharge([])
      setValue('selectAll', false)
      return
    }
  }, [isClear])

  return (
    <Modal onClose={handleOnClose} open={open} containerBlockElClass="modal__adCont modal__adCont-w331 modalCont">
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="modal__choiceContInner1 choiceModal">
          <Modal.Header blockElClass="modal__contHeader modal__contHeader-pd">
            <Modal.Title>{t('applicant.consultantName')}</Modal.Title>
            <Modal.CloseButton onClick={handleOnClose} blockElClass="selectionModal__close" />
          </Modal.Header>
          <FormProvider {...methods}>
            <div className="modal__contMain">
              <div className="modal__contMainRight1 modal__contMainRight-sp">
                <div className={`modal__contMainRightCont modal__contMainRightCont-active`}>
                  <div className="form__groupCheckItem form__groupCheckItem-mgb16">
                    <FormGroupCheck.Item
                      Input={Checkbox}
                      label={t(`jobPosting.search.modal.selectAll`)}
                      type="square"
                      inputProps={{
                        ...register('selectAll'),
                        value: 'all',
                        name: 'selectAll',
                        onChange: handleSelectAll,
                      }}
                    />
                  </div>
                  <div className="modal__contMainRightInner">
                    <ConsultantInCharge
                      selectAll={selectAll}
                      onHandleChange={handleCheckAdInCharge}
                      selectedAdInCharge={selectedAdInCharge}
                      handleChecked={isSelected}
                      sideFilterDisplay={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
          <Modal.Footer blockElClass="modal__adContFooter">
            <Button
              type="button"
              variant="outlineDissable"
              modifier="fs14"
              blockElClass="btn__outlineDissable"
              className="btn-mgr"
              onClick={handleClearSelection}
            >
              {t('jobPosting.search.modal.clear')}
            </Button>
            <Button type="submit" variant="contained" className={'btn btn__shadowBtn btn-spW100'}>
              {' '}
              {t('jobPosting.search.modal.decide')}
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  )
}

ApplicantConsultantInChargeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  submitConsultantInCharge: PropTypes.func,
  isClear: PropTypes.bool,
}

export default ApplicantConsultantInChargeModal
