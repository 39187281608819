import React from 'react'
import SearchBox from '../SelectionManagementSearchArea/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from 'components'

function JobManagementSearchArea({
  searchKeyword,
  searchJobPostingType,
  onFormSubmit,
  onKeywordChange,
  onTypeChange,
  onClearFilterClick,
}) {
  const { t } = useTranslation()
  const jobPostingTypeOptions = [
    {
      id: 'referral',
      label: t('jobManagement.searchArea.jobTypeReferral'),
    },
    {
      id: 'proposal',
      label: t('jobManagement.searchArea.jobTypeProposal'),
    },
  ]

  const handleKeywordChange = (e) => onKeywordChange(e.target.value)
  const handleFormSubmit = (e) => onFormSubmit(e)

  return (
    <form onSubmit={handleFormSubmit}>
      <SearchBox>
        <SearchBox.Top>
          <SearchBox.Title title={t('jobManagement.searchArea.title')} />

          <SearchBox.DropdownArea>
            <SearchBox.FreeWordSearch freeword_label={t('jobManagement.searchArea.keywordInput')}>
              <input
                value={searchKeyword}
                onChange={handleKeywordChange}
                type="text"
                className="selection__searchInput"
              />
            </SearchBox.FreeWordSearch>
            <SearchBox.DropDown dropdown_label={t('jobManagement.searchArea.jobTypeSelect')}>
              <CustomSelect
                placeholder={t('jobManagement.searchArea.jobTypeSelectPlaceholder')}
                options={jobPostingTypeOptions}
                selected={searchJobPostingType}
                onChange={onTypeChange}
              />
            </SearchBox.DropDown>
          </SearchBox.DropdownArea>
        </SearchBox.Top>

        <SearchBox.ButtonArea>
          <button className={`selection__searchBtn1`} type="submit" id="clear" onClick={onClearFilterClick}>
            {t('jobManagement.searchArea.clearFilters')}
          </button>
          <button className="selection__searchBtn2" type="submit" id="submit">
            {t('jobManagement.searchArea.searchButton')}
          </button>
        </SearchBox.ButtonArea>
      </SearchBox>
    </form>
  )
}

JobManagementSearchArea.propTypes = {
  searchKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  searchJobPostingType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFormSubmit: PropTypes.func,
  onKeywordChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  onClearFilterClick: PropTypes.func,
}

export default JobManagementSearchArea
