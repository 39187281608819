import produce from 'immer'
import * as ActionTypes from './actionTypes'
import { fetchCompanies, getCompanyDetails } from 'services/companies'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialState = {
  groupMap: {},
  companySelect: [],
  companySelectFetching: false,
  listState: 'pending',
  listError: null,
  items: [],
  pending: false,
  updated: false,
  success: false,
  details: null,
  params: {
    limit: 10,
    page: 1,
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOAD_COMPANY_SELECT_REQUEST:
      draft.companySelectFetching = true
      return draft
    case ActionTypes.LOAD_COMPANY_SELECT_SUCCESS:
      draft.companySelectFetching = false
      draft.companySelect = payload.data
      return draft
    case ActionTypes.LOAD_COMPANY_SELECT_FAILURE:
      draft.companySelectFetching = false
      return draft
    case fetchCompanies.pending:
      draft.listState = 'pending'
      break
    case fetchCompanies.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break

    case getCompanyDetails.pending:
      draft.details = null
      draft.pending = true
      draft.success = false
      draft.updated = false
      break

    case getCompanyDetails.fulfilled:
      draft.details = payload.data
      draft.pending = false
      draft.success = true
      draft.updated = false
      break
    case getCompanyDetails.failure:
      draft.details = null
      draft.pending = false
      draft.success = false
      draft.updated = false
      draft.error = payload.error
      break
    case fetchCompanies.failure:
      draft.listState = 'failure'
      draft.listError = payload.message
      break
    case ActionTypes.UPDATE_COMPANY_SUCCESS:
      draft.details = payload.data
      draft.pending = false
      draft.success = true
      draft.updated = true
      break
    case ActionTypes.UPDATE_COMPANY_LIST_PROPOSALS:
      draft.params = Object.assign(draft.params, payload)
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)
