const ja = {
  translation: {
    changeJobCountOption_zero: '{{count}}回',
    changeJobCountOption_other: '{{count}}回以下',
    validation: {
      maxLength: '{{length}}文字以内で入力してください',
      required: 'この項目は必須です。',
      invalidInputsCount: '{{count}}個の必須項目が未入力です',
      invalidInputNumber: '有効な数値を入力してください。',
      monthMax: '12ヶ月以内で入力してください',
      max: '{{field}}は{{max}}以下でなければなりません',
      min: '{{field}}は{{min}}以上でなければなりません',
    },
    // define translations below
    auth: {
      required: 'この項目は必須です。',
      email: 'メール形式が無効です。',
      phone_number: '有効な電話番号形式（例：080-5423-9274, 08054239274)',
      password: {
        minLength: 'パスワードは8文字以上である必要があります。',
        confirm: 'パスワードの確認が一致しません。',
        strong: '大文字英字、特殊文字を各最低1文字、数字を含んだ8文字以上のパスワードを入力してください',
      },
    },
    common: {
      change: '変更する',
      badRequest: '悪いお願い',
      backToSignIn: 'ログインに戻る',
      signIn: 'ログイン',
      pleaseSelect: '選択してください',
      cancel: 'キャンセル',
      delete: '削除する',
      confirmDeleteQuestion: '削除してよろしいですか？',
      checkContent: '内容の確認へ',
      plsEnter: '入力してください。',
      assumedGender: '想定する性別',
      remainPrefecture: '（他{{count}}カ所）',
      confirm: '確認する',
      edit: '編集する',
      like: '満足',
      dislike: '気になる',
      decide: '決定する',
      cancelLower: '取り消す',
      suggestion: '提案',
      status: 'ステータス',
      loginUsername: 'ログインユーザー名',
      unread: '未読',
      all: 'すべて',
      company: '企業',
      replied: '返信済',
      unsupported: '未対応',
      profile: 'プロフィール',
      industry: '業界',
      ipo: '株式公開',
      cancel2: 'キャンセルする',
      return: '戻る',
      edited: '編集済み',
      password: 'パスワード',
      login: 'ログインする',
      dropDownNoOption: '選択肢なし',
      // TODO: Get JP text
      loginUsingGmailAccount: 'Login using your Google email account.',
      loginWithGoogle: 'Login with Google',
      emailAddress: 'メールアドレス',
    },
    warnings: {
      portfolioLimit: 'ポートフォリオの上限は10点です',
      recommendationSuccess: '推薦されました',
      recommendationFailed: 'エラーが発生しました。',
      confirmRecommendation: '以上の内容で推薦しますか？',
    },
    formTitles: {
      newRecommendation: '候補者情報を入力',
      confirmRecommendation: '推薦内容の確認',
      jobInformation: '求人に関する情報を入力',
    },
    formLabels: {
      search: '検索する',
      withAuthorizer: '決裁者同席',
    },
    pageTitles: {
      ApplicantCandidates: '該当候補者',
    },
    fieldLabels: {
      personInchargeNumber: '担当者名・電話番号',
      prefecture: '在住の都道府県',
      day: '日',
      month: '月',
      year: '年',
      gender: '性別',
      birthday: '生年月日',
      recentAnnualSalary: '直近の年収',
      desiredAnnualIncome: '希望の年収',
      resume: '履歴書',
      cv: '職務経歴書',
      portfolio: 'ポートフォリオ',
      testimonial: '推薦文',
      notices: '特記事項',
      emergencyContact: '緊急連絡先',
      searchForCandidate: '候補者名・メモ内容を検索',
    },
    prefixes: {
      jobOffer: '求人ID:',
    },
    suffixes: {
      yen: '万円',
      name: '名',
      subject: '件',
      age: '歳',
      minutes: '分',
      range: '回以下',
      months: 'ヶ月',
    },
    buttonLabels: {
      select: '選択する',
      selectFromCandidates: '候補者一覧から選択',
      candidate: '候補者',
      toAdd: 'を追加する',
      clearSearch: '検索条件をクリア',
      narrowDown: '絞り込む',
      recommend: '推薦する',
    },
    formOptions: {
      male: '男性',
      female: '女性',
      others: '選択しない',
      none: 'なし',
    },
    formValues: {
      others: '不問',
      male: '男性',
      female: '女性',
      consult: '予算も含めて相談したい',
      none: '0回',
      year: '通年採用',
    },
    formGroupLabels: {
      regardlessNationality: '国籍問わず',
      foreign: '外国国籍の方を想定',
      japanese: '日本国籍の方を想定',
    },
    hints: {
      pleaseSpecify: '選択してください',
      enterWithinRange: '18~70歳以下で入力してください',
      selectFromList: '担当者一覧から選択する',
      narrowDown: '絞り込む',
      enterDirectly: '直接入力する',
      enter2: 'ご入力ください。2',
      pleaseEnter: '入力してください',
      enterPDF: 'PDFをアップロードする',
      upload5M: '履歴書は5M以内でアップロードでしてください',
      addPortfolio: 'ポートフォリオを追加する',
      portfolioLimit: 'ポートフォリオの上限は10点です',
      enter1: 'ご入力ください。1',
      selectAD: '担当者を選択してください',
      entered: 'が入力した内容',
    },
    optionLabels: {
      regardless: '回数を問わない',
      specify: '回数を指定',
    },
    tableHeaders: {
      candidateName: '候補者名',
      personIncharge: '担当者名',
      age: '年齢',
      status: '状況',
      applicationCount: '応募数',
      memo: 'メモ',
    },
    forgot: {
      submitted: '送信完了',
      success: 'パスワードの再設定が完了しました。パスワードの再設定についての説明が記載されたメールが届きます。',
      forgotPassword: 'パスワードを再設定',
      enterPass: '新しいパスワードを入力',
      enterPassAgain: '再度新しいパスワードを入力',
      enterNewEmail: 'メールアドレスを入力して下さい。',
      forgotLink: 'パスワードをお忘れの方はこちら',
      setIt: '設定する',
    },
    reset: {
      success: 'パスワードの更新に成功しました。更新されたパスワードでログインすることができます。',
      clickToLogin: 'ログインはこちらから。',
      update: 'パスワードの変更',
      enterNewPassword: '新しいパスワードを入力してください。',
    },
    userStatus: {
      pending: '招待中',
      pendingInviter: '招待中　{{inviter_name}}さんが招待',
      pendingInviter1: '{{inviter_name}}さんが招待',
      active: 'アクティブ',
      suspended: 'アカウント休止',
      archived: 'アーカイブ',
      locked: '期限切れ',
    },
    userPermission: {
      parent: '管理者',
      child: 'メンバー',
    },
    accountManagement: {
      title: '個人アカウント一覧',
      inviteButton: '招待',
      cancelButton: 'キャンセル',
      emailLabel: 'メールアドレス',
      inviteDialogTitle: 'ユーザー招待',
      permissionLabel: '権限',
      searchButton: '検索',
      searchLabel: 'メールアドレス、名前で検索',
      statusSelectLabel: 'ステータスで絞り込み',
      permissionSelectLabel: '権限で絞り込み',
      name: '名前',
      emailAddress: 'メールアドレス',
      status: 'ステータス',
      verifiedAt: 'アカウント認証',
      permission: '権限',
    },
    adminUser: {
      title: 'アカウント一覧',
      accountID: '個人ID',
      companyId: '企業ID',
      companyName: '会社名',
      lastLoginDate: '最終ログイン日',
    },
    userProfile: {
      basicInformation: {
        tabTitleLabel: 'プロフィール',
        toEditButtonLabel: '編集する',
        mostGoodJobLabel: '最も得意な職種',
        specialtyJobLabel: 'その他得意職種',
        mostGoodIndustryLabel: '最も得意な業界',
        specialtyIndustryLabel: 'その他得意業界',
        companyInfoTitleLabel: '所属企業紹介',
        companyNameLabel: '会社名',
        companyAddressLabel: '本社住所',
        companyIndustryLabel: '業界',
        companyHPLabel: '会社HP',
        companyIPOLabel: '株式公開',
        companyRepresentativeNameLabel: '代表者名',
        companyYearOfEstablishmentLabel: '設立年',
        companyYearOfEstablishmentUnit: '年',
        companyClosingMonthLabel: '決算月',
        companyClosingMonthUnit: '月',
        companyNumberOfEmployeesLabel: '従業員数',
        companyNumberOfEmployeesUnit: '人',
        companyProfileLabel: '会社概要',
        companyBusinessContentLabel: '事業内容',
        accountTypeLabel: 'アカウント種別',
      },
      toEdit: {
        backButton: 'アカウント編集',
        saveButton: '保存する',
      },
      updateButton: '更新',
    },
    header: {
      navbar: {
        home: 'ホーム',
        jobSearch: '求人検索',
        recommendation: '推薦',
        question: '質問',
        favorite: 'お気に入り',
        jobPostings: '求人票',
        candidates: '候補者',
        selection: '選考',
        proposal: '提案',
        companyAccount: '会社アカウント',
        log: 'ログ',
        evaluation: '評価',
      },
      topbar: {
        questionList: '質問一覧',
        userProcedure: 'ユーザー手順',
        faq: 'FAQ',
        opinionsAndRequest: 'ご意見・ご要望',
      },
      dropdown: {
        companyInformation: '会社情報',
        accountManagement: 'アカウント管理',
        profileManagement: 'プロフィール管理',
        logout: 'ログアウト',
      },
      subbar: {
        dashboard: 'ダッシュボード',
        jobOffer: '求人情報',
        jobPosting: '求人情報の掲載',
        selectionManagement: '選考管理',
        jobSeekerManagement: '推薦者管理',
        proposalManagement: '提案管理',
      },
      jobInfoRegistration: '求人情報登録',
      saveAsDraft: '下書き保存',
    },
    userLogs: {
      accountType: 'アカウントタイプ',
      accountID: 'アカウントID',
      accountName: 'アカウント名',
      accountLevel: 'アカウントレベル',
      action: 'アクション',
      actionDate: 'アクション日時',
      jobID: '求人ID',
      jobURL: '求人URL',
      exportCSV: 'CSVをエクスポート',
      searchArea: {
        companyKeyword: '会社名・個人名',
        actionDate: 'アクション日',
      },
      listing: {
        title: 'ログ一覧',
        heading: {
          actionDate: 'アクション日時',
          corporateGroup: '企業グループ情報',
          accountType: '種別',
          accountInformation: 'アカウント情報',
          accountContent: 'アクション内容',
          jobInformation: '求人情報',
        },
        status: {
          Recommend: '推薦',
          Proposal: '提案',
        },
        privilege: {
          parent: '管理者',
          child: 'メンバー',
        },
      },
    },
    qAndA: {
      title: '質問一覧',
      numQuestions: '{{count}}件',
      modalTitle: '質問する',
      topicField: '質問のトピック',
      questionField: '質問内容',
      cancelBtn: 'キャンセル',
      confirmBtn: '確認する',
      submitBtn: 'この内容で質問する',
      addBtn: '質問する',
      sideTitle: '絞り込む',
      date: '投稿日：{{date}}',
      numReplies: '{{count}}件の返信',
      seeOthers_one: '返信する',
      seeOthers: '他{{count}}件の回答をみる',
      close: '閉じる',
      replyPlaceholder: '返信内容を記入',
      replySubmitBtn: '送信',
      agent: 'エージェント',
      successAlert: '質問を投稿しました',
      tabs: {
        all: 'すべて',
        answered: '回答済み',
        unanswered: '回答待ち',
      },
      title2: '「{{tab}}」の質問一覧',
      questioner: '質問者',
      topic: 'トピック',
      questionDate: '質問日',
      jobYouAsk: '質問した求人',
      answerInput: '答え',
    },
    jobManagement: {
      searchArea: {
        title: '検索する',
        keywordInput: 'フリーワード',
        jobTypeSelect: '求人種別',
        jobTypeSelectPlaceholder: '選択してください',
        clearFilters: '検索条件をクリア',
        searchButton: '検索する',
        jobTypeReferral: '人材紹介',
        jobTypeProposal: '求人広告',
      },
      createButton: {
        part1: '求人票',
        part2: 'を作成する',
      },
      resultsTitle: 'の求人票一覧',
      listing: {
        heading: {
          recruitment: '募集',
          noOfPv: 'PV数',
          noOfEmployeesToHire: '採用予定数',
          inProgress: '進行中',
          notCompatible: '未対応',
        },
        item: {
          referralTag: '紹介',
          proposalTag: '広告',
          editDateLabel: '編集日',
        },
      },
      jobTypeFilters: {
        underRecruitment: '募集中',
        recruitmentSuspended: '募集停止',
        startRecruitment: ' 募集を開始',
        resumeRecruitment: '募集を再開',
        draft: '下書き',
        archived: 'アーカイブ',
        all: 'すべて',
        copyAsDraft: '下書きにコピーしました',
      },
      jobStatusLabel: {
        underRecruitment: '編集',
        recruitmentSuspended: ' 募集停止',
        startRecruitment: ' 募集を開始',
        resumeRecruitment: '募集を再開',
        draft: '下書きに戻す',
        archived: 'アーカイブ',
        all: 'すべて',
        copy: 'コピー',
        copyAsDraft: '下書きにコピーしました',
      },
      addJob: {
        referral: '人材紹介用求人票を作成',
        proposal: '広告提案用求人票を作成',
      },
    },
    jobVacancies: {
      publishingSettings: {
        title: '公開設定',
        subTitle: 'この企業に所属する個人の評価になります',
        sendScoutEmail: 'スカウトメールの送信',
        postingOnRecruitingMedia: '求人媒体への掲載',
        editButton: '編集する',
        yen: '万円',
        connoisseur: '通',
      },
      basicInformation: {
        title: '基本情報',
        advertisingBudget: '広告予算',
        proposalLimit: '提案リクエスト受付上限',
        mediaInformation: '媒体情報',
        postedMedia: '現在掲載している媒体',
        pastMedia: '過去掲載媒体',
        mediaInterest: '興味のある媒体',
      },
      agentOnly: {
        title: 'エージェント限定情報',
        subTitle: 'この項目は紹介会社のみが閲覧できます。求職者には公開されません。',
        selectionDifficulty: '選考難易度',
        latestInformalCandidateInformation: '直近の内定者情報',
        interviewInformation: '面接情報',
        notices: '特記事項',
        editButton: '編集する',
      },
      mandatoryRequirements: {
        title: '必須要件',
        skillsExperience: '必須スキル・経験',
        assumedGender: '想定する性別',
        ageAtWhichYouCanApply: '応募可能な年齢',
        finalAcademicBackgroundThatCanBeApplied: '応募可能な最終学歴',
        numberOfJobChanges: '転職回数',
        englishLevel: '英語レベル',
        chineseLevel: '中国語レベル',
        countryOfCitizenship: '国籍',
        requiredItemsToFillInWhenRecommending: '推薦時における記入必須事項',
        editButton: '編集する',
        sensitiveInfo: '機微情報の有無：有／無',
        changeJobReason: '転職理由',
        pastAchievements: 'これまでの実績',
      },
      applicationRequirements: {
        title: '募集要項',
        recruitmentType: '募集職種',
        employmentStatus: '雇用形態',
        trialPeriod: '試用期間',
        workingConditionsDuringTheTrialPeriod: '試用期間の勤務条件',
        assignedDepartment: '配属部署',
        assignedDepartmentDetails: '配属部署詳細',
        businessContent: '業務内容',
        recruitmentBackground: '募集背景',
        estimatedAnnualIncomeAtTheTimeOfJoiningTheCompany: '入社時想定年収',
        estimatedMonthlyIncome: '想定月収',
        workLocation: '勤務地',
        editButton: '編集する',
        months: 'ヶ月',
        none: 'なし',
      },
      treatmentSalaryWelfare: {
        title: '待遇・給与・福利厚生',
        wageSystem: '賃金制度',
        wageSystemOtherInfo: '賃金制度についての補足',
        workingStyle: '就労形態',
        supWorkingStyle: '就労形態に関する補足事項',
        workingHours: '就業時間',
        breakTime: '休憩時間',
        overtimeWork: '時間外労働',
        averageOvertimeHours: '平均残業時間',
        supInforOnWageSystem: '賃金制度についての補足',
        overtimePay: '残業代',
        treatmentCond: '待遇条件・昇給賞与',
        welfare: '福利厚生',
        holidayVacation: '休日休暇',
        editButton: '編集する',
        hour: '時間',
        minutes: '分',
        canBe: 'あり',
        none: 'なし',
      },
      workPlaceCard: {
        zipcode: '〒',
        nearest: '最寄り駅',
      },
    },
    jobPosting: {
      common: {
        return: '戻る',
        inFront: '前に',
        confirmBtn: '確認する',
      },
      step3: {
        hint: '応募時に同時に求職者情報と同時に知りたいことがらを入力してください',
        example: '例）',
        presence: '機微情報の有無',
        hireDate: '入社日',
        interview: '面接候',
        day: '日',
        driversLicense: '運電免許の有無',
        workingStyle: '働き方の特徴',
        required: '必須要件',
        essentialSkills: '必須スキル・経験',
        ageRequired: '応募可能な年齢',
        age: '歳以下',
        ageHint: '※18~70歳以下で入力してください',
        plsEnter: 'ご入力ください。',
        academicBackground: '応募可能な最終学歴',
        jobChangeCount: '転職回数',
        none: '0回',
        changeJobCountItem: '{{count}}回以下',
        englishLevel: '英語レベル',
        chineseLevel: ' 中国語レベル',
        nationality: '国籍',
        recommendationRequirement: '推薦時における記入必須事項',
        howToWork: '働き方',
        workEnvironment: '会社・職場の環境',
        workExperience: '仕事の経験',
        inputTips: '入力のヒント',
      },
      step4: {
        tenThousandYen: '万円',
        canBe: 'あり',
        none: 'なし',
        months: 'ヶ月',
        minutes: '分',
        time: '時間',
        applicationRequirement: '募集要項',
        employmentStatus: '雇用形態',
        trialPeriodChoice: '試用期間',
        trialPeriod: '試用期間',
        workingCondDuringTrial: '試用期間の勤務条件',
        assignedDepartment: '配属部署',
        assignedDepartmentDetails: '配属部署詳細',
        businessContent: '業務内容',
        recruitmentBackground: '募集背景',
        estAnnualIncome: '入社時想定年収',
        estMonthlyIncome: '想定月収',
        estMonthlySalary: '月給',
        workLocation: '勤務地',
        treatSalaryWelfare: '待遇・給与・福利厚生',
        supWorkingStyle: '就労形態に関する補足事項',
        workingHours: '就業時間',
        breakTime: '休憩時間',
        overtimeWork: '時間外労働',
        averageOvertimeHrs: '平均残業時間',
        workingStyle: '就労形態',
        wageSystem: '賃金制度',
        supInforOnWageSystem: '賃金制度についての補足',
        overtimePay: '残業代',
        treatmentCond: '待遇条件・昇給賞与',
        welfare: '福利厚生',
        holidayVacation: '休日休暇',
        next: '次へ',
        invalidCount: '{{count}}個の必須項目が未入力です',
        return: '戻る',
        inFront: '前に',
        selectPrefecture: '都道府県を選択してください',
        addPrefectures: '都道府県を追加する',
        addAddress: '住所を追加する',
        postalCode: '郵便番号',
        address: '住所',
        nearestStation: '最寄駅',
        confirmBtn: '確認する',
        barTitle: '最後に、募集に関する情報を入力',
      },
      tabs: {
        jobVacancies: '求人票',
        qANdA: 'Q&A',
        corporateInfo: '企業情報',
        evaluation: '評価',
        otherJobs: '他の求人',
      },
      status: {
        title: 'ステータス',
        underRecruit: '募集中',
        closed: '募集終了',
        draft: '下書き',
        archive: 'アーカイブ',
        max: '募集上限に達しました',
      },
      card: {
        recruitmentInformation: '採用情報',
        addToFavorites: 'お気に入りに追加',
        removeFromFavorites: 'お気に入りから解除',
        detailView: '詳細表示',
        simpleDisplay: '簡易表示',
        detail: '詳細',
        printing: '印刷',
        unspecified: '指定なし',
        registration: '登録日',
        updated: '更新日',
        hide: '非表示',
        download: 'ダウンロード',
        jobDetail: '求人詳細',
        openInSeparateTab: '別タブで開く',
        recommendationTo: '推薦する',
        print: '印刷する',
        question: '質問する',
        saveAsBookMark: 'お気に入り登録',
        industry: '業界',
        workLocation: '勤務地',
        numberOfHits: '該当件数',
        subject: '件',
        sortOrder: '並び順',
        workingPointStyle: '働き方のポイント',
        appRequirement: '募集要項',
        recruitmentType: '募集職種',
        numberPeopleToBeHired: '予定人数',
        people: '{{value}}人',
        scout: 'スカウト',
        postedInHouse: '自社掲載',
        estimatedAnnualIncome: '入社時想定年収',
        min_max_yen: '{{min}} ~ {{max}}万円',
        yen: '{{value}}万円',
        desiredTimeToHire: '採用希望時期',
        age: '年齢',
        finalEducation: '最終学歴',
        numberOfChanges: '転職回数',
        englishLevel: '英語レベル',
        sex: '性別',
        workExperience: '仕事の経験',
        mandatoryRequirement: '必須要件',
        finishSelection: '選択を終了する',
        noTransfer: '転勤なし',
        startUp: 'スタートアップ',
        yearRound: '通年',
        changedJobCount: '{{value}}回以下',
        anyNumberOfTimes: '回数を問わない',
        plannedHires: '採用予定人数',
        yearOld: '{{min}} ~ {{max}}歳',
        scoutEmail: '{{value}}',
        postingRecruitment: '{{value}}',
        selectedCount: '件の求人を選択中',
        maxNumberOfPrint: '一度に印刷できる上限は10件までです',
        selectJob: '1件の求人を選択中',
        jobId: '求人ID: ',
        unFavorites: 'お気に入りを解除',
        unFavoriteConfirmationQuestiontext: '解除してよろしいですか？',
        unFavoriteConfirmationText: '解除する',
        unfavoriteRemovedTextNotif: 'お気に入りから解除しました',
        addedToFavorites: 'お気に入りに追加しました',
      },
      search: {
        option1: '選択肢１',
        option2: '選択肢２',
        option3: '選択肢３',
        postPeriod: '掲載期間',
        publicationMedium: '掲載媒体',
        pastPublicationMedium: '過去に掲載した媒体',
        mediaOfInterest: '興味ある媒体',
        presenceOrAbsenceOfApprover: '商談時に決裁者が同席する案件のみ',
        yearsEstablished: '設立年数',
        applySaveCriteria: '保存済みの検索条件を適用する',
        saveCurrentSearchCriteria: '現在の検索条件を保存する',
        basicCondition: '基本条件',
        freewordJobId: 'フリーワード・求人ID',
        select: '選択する',
        jobIndustry: '業界',
        availablePosition: '職種',
        workLocations: '勤務地',
        estimatedAnnualIncome: '予定年収',
        specifyMinMaxIncome: '最低年収・最高年収を指定',
        specifyAnnualIncome: '年収を指定',
        tenThousanYen: '万円',
        searchByCharacteristics: '求人の特徴で探す',
        pointOfWorkingStyle: '働き方のポイント',
        workExperience: '仕事の経験',
        employmentStatus: '雇用形態',
        fullTimeEmployee: '正社員',
        contractEmployee: '契約社員',
        subContractEmployee: '業務委託',
        noOfHires: '採用人数',
        name: '名',
        person1: '1名',
        person2: '2名',
        person3: '3名',
        person4: '4名',
        person5to9: '5〜9名',
        person10to19: '10〜19名',
        person20to29: '20〜29名',
        person10: '～10名',
        person11to100: '11〜100名',
        person101to500: '101〜500名',
        person501to1000: '501〜1000名',
        person30: '30名以上',
        person1001: '1,001名以上',
        moreThanFirstName: '名以上',
        desiredTimeToHire: '採用希望時期',
        recruitedAllyear: '通年採用',
        withinAMonth1: '1ヶ月以内の採用',
        withinAMonth3: '3ヶ月以内の採用',
        withinHalfyear: '半年以内の採用',
        withinAYear: '1年以内の採用',
        searchByDetailedConditions: '詳細条件で探す',
        companySize: '企業規模',
        workingStyle: '就労形態',
        fixedWorkingHrs: '勤務時間固定',
        flexTimeSystem: 'フレックスタイム制',
        professionalBusinessDiscretion: '専門業務型裁量',
        planningWorkTypeDiscretion: '企画業務型裁量',
        modifiedWorkingHrsSystem: '変形労働時間制',
        assumedAge: '想定する年齢',
        noOfJobChanges: '転職回数',
        shiftSystem: 'シフト制',
        others: 'その他',
        educationalBackground: '学歴',
        noMatter: '不問',
        highSchoolGraduateorAbove: '高校卒業以上',
        collegeGraduateorAbove: '高専卒業以上',
        juniorCollegeProfessionalorAbove: '短大・専門卒業以上',
        universityGraduateorAbove: '大学卒業以上',
        marchAndAbove: 'MARCH以上',
        soukei: '早慶・国公立以上',
        graduatedAbove: '大学院卒以上',
        assumedGender: '想定する性別',
        male: '男性',
        female: '女性',
        englishLevel: '英語レベル',
        dailyLevel: '日常会話レベル',
        businessLevel: 'ビジネス会話レベル',
        nativeLevel: 'ネイティブレベル',
        chineseLevel: '中国語レベル',
        japaneseLevel: '日本語レベル',
        publicInformation: '公開情報',
        postingOnMedia: '媒体への掲載',
        publishCompanyValue: '公開OK',
        publishCompanyPrivateValue: '社名非公開ならOK',
        publishCompanyPublicOrPrivateValue: '公開NG',
        publishCompany: '会社やグループが特定できる情報を公開してOK',
        publishCompanyPrivate: '会社やグループが特定できる情報が 非公開であればOK',
        publishCompanyPublicOrPrivate: '会社やグループが特定できる情報の 公開 / 非公開に関わらずNG',
        sendScoutEmail: 'スカウトメールの送信',
        scoutingCompany: '会社やグループが特定できる情報を 公開してスカウトOK',
        scoutCompanyPrivate: '会社やグループが特定できる情報が 非公開であればスカウトOK',
        scoutCompanyPublicOrPrivate: '会社やグループが特定できる情報の 公開 / 非公開に関わらずスカウトNG',
        clearSearchConditions: '検索条件をクリア',
        searchFor: '検索する',
        ageRangeNote: '※{{min}}~{{max}}歳以下で入力してください',
        underAgeOf: '歳以下',
        regardlessOfNoOfTimes: '回数を問わない',
        specifyNoOfTimes: '回数を指定',
        searchCriteriaOptionsLessLabel: '{{times}}回以下',
        searchCriteriaOptionsLabel: '{{times}}回',
        noOfTimesLessLabel: '{{times}}回以下',
        noOfTimesLabel: '{{times}}回',
        noOfTimesMoreLabel: '{{times}}回以上',
        excludeClosedJob: '募集停止中の求人を除く',
        twentyYenOrLess: '20万円以下',
        twentyToFiftyYen: '20〜50万円',
        fiftyOneToHundredYen: '51〜100万円',
        hundredYenOrMore: '101万円以上',
        discussBudget: '予算も含めて相談したい',
        lessThanOneYear: '1年未満',
        oneToNineYears: '1年以上~10年未満',
        tenToTwentyNineYears: '10年以上~30年未満',
        thirtyToFortyNineYears: '30年以上~50年未満',
        fiftyToNinetyNineYears: '50年以上~100年未満',
        overOneHundredYears: '100年以上',
        within24Hours: '24時間以内',
        within3Days: '3日以内',
        withinAWeek: '1週間以内',
        withinAMonth: '1ヶ月（新着）',
        threeMonths: '3ヶ月',
        modal: {
          save: '保存する',
          cancel: 'キャンセル',
          saveCriteriaTitle: '検索条件を保存する',
          saveCriteriaNote: '後から分かりやすい名前で登録しましょう。',
          clear: '選択項目をクリア',
          decide: '決定する',
          selectIndustry: '業界を選択',
          selectJobType: '職種を選択',
          selectWorkLocation: '勤務地を選択',
          selectWorkingStyle: '働き方のポイント',
          sales: '営業',
          officeWork: '事務・管理',
          marketing: 'マーケティング',
          businessPlanning: '経営企画・経営戦略',
          director: 'ディレクター',
          creativeRelated: 'クリエイティブ関連',
          ITEngineer: 'ITエンジニア',
          engineering: 'エンジニア',
          pharmaceuticalTech: '素材・化学・食品・医薬品技術職',
          workExperience: '仕事の経験',
          howToWork: '働き方',
          selectionCharacteristics: '選考の特徴',
          companyWorkEnvironment: '会社・職場の環境',
          selectAll: 'すべて選択',
          contents: '内容',
          corporate: '法人営業',
          IT: 'IT営業',
          private: '個人営業',
          routeAgency: 'ルートセールス・代理店営業',
          inHouseCounter: '内勤営業・カウンターセールス',
          architectureCivilEngineeringRealEstateHousing: '建築・土木・不動産・住宅営業',
          overseas: '海外営業',
          customerSupportCallCenterOperation: 'カスタマーサポート・コールセンター運営',
          careerCouncelorHumanResourceCoordinator: 'キャリアカウンセラー・人材コーディネーター',
          noExperienceAsFullTime: '正社員経験なしOK',
          industryExperienced: '業界未経験可',
          inExperienceOccupation: '職種未経験可',
          inExperienceIndustry: '業界・職種未経験可',
        },
      },
      sorting: {
        unspecified: '指定なし',
        byStartDate: '募集開始日の近い順',
        byUpdatedDate: '更新日が近い順',
        byLowestEstimatedIncome: '最低想定年収の低い順',
        byHighestEstimatedIncome: '最低想定年収の高い順',
        byYearOfEstablishment: '設立年が近い順',
        byNumberOfEmployees: '採用人数の多い順',
        byAdvertisingBudget: '広告予算の高い順',
      },
      numPeople: '{{count}}人',
      desiredTimeHires: '採用希望時期',
      numHires: '採用人数',
      createdAt: '登録日',
      updatedAt: '更新日',
      companyName: '株式会社',
      summary: {
        title: '求人概要',
        occupation: '職種',
        industry: '業界',
        annualIncome: '年収',
        workLocation: '勤務地',
        amountOfRemuneration: '報酬額',
        advertisingBudget: '広告予算',
        yen: '万円',
        consult: '予算も含めて相談したい',
      },
      footer: {
        print: '印刷する',
        question: '質問する',
        evaluate: '評価する',
        saveAsBookmark: 'お気に入り登録',
        recommendationTo: '推薦する',
        suggestionTo: '提案する',
        alreadyPropose: '提案済み',
        returnToDraft: '下書きに戻す',
        editJobDescription: '求人内容を編集する',
        proposalTo: '提案が完了しました。',
        proposalContent: '提案内容',
        proposalAttachment: '添付ファイル',
        proposalFileAttachment: 'ファイルを追加する',
        suggest: '提案する',
      },
      step2: {
        headerLogoLbl: '求人情報登録',
        saveAsDefaultLbl: '下書き保存',
        barLabel: '転職エージェント向けの情報を入力',
        agentOnlyInforation: 'エージェント限定情報',
        agentSubInfo: 'この項目は紹介会社のみが閲覧できます。求職者には公開されません。',
        difficultyLabel: '選考難易度',
        recentJobInfolabel: '直近の内定者情報',
        interviewInfoLabel: '面接情報',
        specialNote: '特記事項',
        fieldErrorMessage: '2000字以内で入力してください。',
        commentTitle: '入力のヒント',
        difficultyCommentText: 'どのようなスキルや経験だと落ちる可能性があるかなどを記入してください',
        specialNotCommentText1: '応募時に同時に求職者情報と同時に知りたいことがらを入力してください',
        specialNotCommentText2: '例）機微情報の有無、入社日、面接候補日、運転免許の有無',
        filledFieldsCtr: '個の必須項目が未入力です',
        confirmBtn: '確認する',
      },
      filter: {
        updateSuccessMessage: '検索条件を変更しました。',
      },
    },
    otherJobs: {
      title: '他の求人',
      numQuestions: '{{count}}件',
      work_place: '勤務地',
      employment_form: '雇用形態',
      recruitment_date: '採用予定時期',
      to_be_hired_count: '採用予定人数',
      age: '想定する年齢',
      gender: '想定する性別',
    },
    floatingControl: {
      number_of_selected_jobs: '選択数',
      hide: '非表示ボタン',
      add_to_bookmark: 'ブックマーク',
      print: '印刷',
    },
    evaluation: {
      tabs: {
        all: 'すべて',
        like: '件',
        dislike: '件',
      },
      title2: '「{{tab}}」すべて',
      leftMainTitle: 'の評価一覧',
      leftMainTitle1: '評価一覧',
      leftMainTitleSup: 'この企業に所属する個人の評価になります',
      iconText: {
        like: '満足',
        dislike: '気になる',
      },
      mutualEvaluation: {
        complete: '評価済み', // mutually evaluated
        incomplete: '未評価', // not yet evaluated
      },
      status: {
        complete: '承認済み',
        incomplete: '未承認',
        notYetApproved: '未承認', //not yet approved/ new
        approved: '承認する', //approved
        disapproved: '非承認', //disapproved
        disapprovedNotif: '非承認にしました。',
        approvedNotif: '承認しました',
        revertApproval: '承認前に戻す',
        approval: '承認',
        nonApproval: '非承認',
      },
      evaluation: '評価',
      subject: '件',
      basicInformation: '基本情報',
      reasonForDisapproval: {
        title: '非承認の理由を選択',
        policyViolation: 'ポリシー違反',
        contentsCannotConfirm: '事実確認ができない内容',
        falseAccusation: '誹謗中傷を含む',
        others: 'その他',
      },
      listing: {
        title: '評価一覧',
        headings: {
          actionDate: 'アクション日時',
          valuer: '評価者',
          evaluatee: '被評価者',
          evaluation: '評価',
          mutualEvaluation: '相互評価',
          mutuallyEvaluated: '相互評価済み',
          approvalStatus: '承認状態',
          evaluationDetails: '評価詳細',
        },
      },
      searchArea: {
        keyword: '会社名・個人名',
        evaluationStatusOption: '相互評価の状態',
      },
    },
    selectionManagement: {
      list_of_selections_for_nominations: ' の選考一覧',
      unit: '件',
      name_header: '候補者名',
      job_info_header: '求人情報',
      company_header: '推薦企業名',
      step_header: '選考ステップ',
      situation_header: '対応状況',
      read: '返信済',
      responded: '返信済み',
      unread: '未対応',
    },
    daysOfTheWeek: {
      0: '日',
      1: '月',
      2: '火',
      3: '水',
      4: '木',
      5: '金',
      6: '土',
    },
    dateSymbols: {
      year: '年',
      month: '月',
    },
    selection: {
      modal: {
        title: '{{applicant}}の選考状況',
        declineBtn: '選考を辞退する',
        companyName: '会社名',
        recruitmentType: '募集職種',
        rejectedAlert: '選考が不採用になりました。',
        declinedAlert: '選考を辞退しました。',
        resumeBtn: '選考を再開する',
        message: 'メッセージ',
        recommenderInfo: '推薦者情報',
        unauthorizedMsg: '現在の権限ではメッセージの送信を行うことはできません。',
        resumeMsg: 'メッセージを送信する場合は選考を再開してください。',
        selectionCompleted: '選考が終了しました。',
        submitBtn: '送信',
        curriculumVitae: '職務経歴書',
        resume: '履歴書',
        portfolio: 'ポートフォリオ',
        confirmDecline: {
          title: '選考を辞退しますか？',
          text: '後から辞退を取り消すことができます。',
          cancelBtn: 'キャンセル',
          confirmBtn: '辞退する',
        },
        recommendationTab: {
          currentAddress: '現住所',
          gender: '性別',
          currentEmploymentForm: '現在の雇用形態',
          mostRecentAnnualIncome: '直近の年収',
          desiredSalary: '希望年収',
          emergencyContact: '緊急連絡先',
          remarks: '特記事項',
          testimonial: '推薦文',
          changeHistoryTitle: '変更履歴',
        },
        evaluation: {
          title: '選考が終了しました',
          title2: '選考が終了しました。',
          titleProposal: '提案が終了しました。',
          message: '{{applicant}}の選考が終了しました。',
          messageHR: '{{applicant}}さんの選考が終了しました。',
          message2: '最後に{{company}}の評価を行い完了しましょう。',
          messageV2: '{{name}}さんの提案が終了しました。',
          messageV2L2: '最後に株式会社{{company}}の評価を行い完了しましょう。',
          button: '{{company}}を評価する',
          button2: '株式会社{{company}}を評価する',
        },
        changeSelectionStatus: '選考ステータスを変更',
        invalidAttachment: 'ファイルは5MB以下のHEIC, jpg, png, word, xlsx, PDFのいずれかを添付してください',
        exceedAttachmentLimit: '添付は最大10ファイルです',
      },
      changeStatusModal: {
        title: '選考ステータスへ変更',
        description: '変更後の選考ステータスを選択してください。',
        unofficialDecision: '内定',
        fail: '不合格にする',
        nextStatus: '{{status}}に進める',
        offerTitle: '内定にしますか？',
        offerText: '後から内定を取り消すことができます。',
        makeOffer: '内定にする',
        cancel: 'キャンセル',
        failTitle: '不合格にしますか？',
        failText: '後から不合格を取り消すことができます。',
        declineAlert: '選考を辞退しました。',
      },

      sideMenu: {
        unread: '未読',
        recommended: '推薦',
        under_selection: '選考中',
        unofficial_decision: '内定',
        joined_the_company: '入社',
        decline_or_cancel: '不合格・辞退',
        decline_or_sendoff: '辞退・お見送り',
        all: 'すべて',
      },
    },
    editHistory: {
      seeDetails: '詳細をみる',
      titleCatch: '{{editor}}さんが{{field}}を変更しました。',
      before: '前',
      after: '後',
    },
    appointment: {
      modal: {
        title: '日程を選択してください',
        cancelBtn: 'キャンセル',
        confirmBtn: '内容の確認',
        dateInPast: '過去以外の日時を選択してください',
        startGreaterThanEnd: '開始日は終了日よりも前の日時を選択してください',
        endLessThanStart: '終了日が開始日よりも後の日時を選択してください',
        dateRangeConflict: 'すでに別の予定が設定されています',
        cconfirmationTitle: '下記の日程で提案します。よろしいですか？',
        toEdit: '編集する',
        suggest: '提案する',
      },
      selectADate: '日付を選択する',
    },
    selectionManagementSearch: {
      searchTitle: '検索',
      searchFreeWord: 'フリーワード',
      searchJobChanger: '転職者名',
      searchConsultantName: 'コンサルタント名',
      searchSelectionStatus: '選考ステータス',
      searchStatus: 'ステータス',
      searchSelectionStep: '選考ステップ',
      searchContractType: '契約種別',
      clearSearchBtn: '検索条件をクリア',
      searchBtn: '絞り込む',
      firstInterview: '一次面接',
      secondInterview: '二次面接',
      thirdInterview: '三次面接',
      fourthInterview: '四次面接',
      fifthInterview: '五次面接',
      lastInterview: '最終面接',
      documentScreening: '書類選考',
      listRetrievalUnsuccesfulTitle: '該当する求人一覧はありませんでした。',
      evaluationListRetrievalUnsuccesfulTitle: '該当する評価一覧はありませんでした。',
      listRetrievalUnsuccesfulMessage: '検索条件を変更して再度お試しください。',
    },
    newJobPost: {
      step1: {
        barTitle: '基本情報を入力',
        jobTitle: '求人タイトル',
        confirmBtn: '確認する',
        recruitmentType: '募集職種',
        desiredTimeToHire: '採用希望時期',
        desiredTimeToHire_recruitedAllYear: '通年採用',
        desiredTimeToHire_selectTheDesiredHiringTime: '採用希望時期を選択',
        desiredTimeToHire_selectTheDesiredHiringTime_placeholder: '選択してください',
        month: '月',
        year: '年',
        numberOfPeopleYouWantToHire: '採用希望人数',
        man: '人',
        publishingSettings: '公開設定',
        sendScoutEmail: 'スカウトメールの送信',
        postingOnRecruitingMedia: '求人媒体への掲載',
        comments: {
          title: '入力のヒント',
        },
        select: '選択する',
        change: '変更する',
        next: '次へ',
        requiredItemHasNotBeenEntered: '個の必須項目が未入力です',
        recruitmentTypeModal: {
          title: '職種を選択',
          clearSelection: '選択項目をクリア',
          decide: '決定する',
        },
      },
      stepConf: {
        confirmDetails: '登録内容の確認',
        basicInformation: '基本情報',
        agentInformation: '転職エージェント向け情報',
        jobInformation: '求人に関する情報',
        recruitmentInformation: '募集に関する情報',
        saveToDraft: '下書きに保存する',
        publishJob: '求人を公開する',
        publishJobText: '以上の内容で求人を公開しますか',
        toEdit: '編集する',
      },
    },
    newJobPostProposal: {
      step1: {
        barTitle: '基本情報を入力',
        jobTitle: '求人タイトル',
        recruitmentType: '募集職種',
        advertisingBudget: '広告予算',
        select: '選択する',
        desiredTimeToHire: '採用希望時期',
        desiredTimeToHire_recruitedAllYear: '通年採用',
        desiredTimeToHire_selectTheDesiredHiringTime: '採用希望時期を選択',
        desiredTimeToHire_selectTheDesiredHiringTime_placeholder: '選択してください',
        numberOfPeopleYouWantToHire: '採用希望人数',
        man: '人',
        specifyBudget: '予算を指定する',
        tenThousandYen: '万円',
        consultIncludingBudget: '予算も含めて相談したい',
        acceptanceLimit: '提案リクエスト受付上限',
        connoisseur: '選択してください',
        aboutTheMedium: '媒体について',
        currentPostMedia: '現在掲載している媒体',
        pastPublicationMedium: '過去掲載媒体',
        mediaOfInterest: '興味のある媒体',
        presenceOrAbsenceOfAuthorizer: '決裁者の同席の有無',
        authorizerIsPresent: '商談時に決裁者が同席',
        aboutTheMediumModal: {
          title: '現在掲載している媒体選択',
          clearSelection: '選択項目をクリア',
          decide: '決定する',
          selectAll: 'すべて選択',
        },
        jobVacanciesCreation: '求人票作成',
        saveAsDraft: '下書き保存',
        zeroTimesOrLess: '0回以下',
        once: '1回',
        twice: '2回',
        change: '変更する',
        searchFor: '検索する',
      },
    },
    choice: {
      canBe: 'あり',
      none: 'なし',
    },
    applicant: {
      gender: {
        male: '男性',
        female: '女性',
        others: '選択しない',
      },
      consultantName: '担当コンサルタント名',
      select: '選択する',
    },
    evaluationForm: {
      headerTitle: '評価内容の入力',
      headerInstruction: '採用担当者様へ評価コメントのご記入をお願いします。',
      typeLabel: '評価の種類',
      satisfaction: '満足',
      beOnOnesMind: '気になる',
      contentLabel: '評価内容',
      confirmInput: '入力内容の確認',
      confirmQuestion: '以上の内容で評価を行いますか？',
      evaluate: '評価する',
    },
    recommendation: {
      duplicateError: {
        message: '現在入力している候補者と類似した候補者が既に登録されています。',
        link: '登録済みの候補者を確認',
      },
      backButton: '推薦する',
    },
    message: {
      cancelScheduleText: '日程を取り消しますか？',
      selectedScheduleText: '{{status}}の日程が決定しました。',
      cancelledScheduleText: '{{status}}の日程を取り消しました。',
    },
    candidate: {
      header: '候補者を追加',
      editHeader: '候補者情報を編集',
      title: '候補者情報を入力',
      title1: '内容の確認',
      fullname: '氏名',
      furigana: 'フリガナ',
      dateofBirth: '生年月日',
      day: '日',
      month: '月',
      year: '年',
      residence: '在住の都道府県',
      gender: '性別',
      male: '男性',
      female: '女性',
      others: '選択しない',
      formAdtext: '選択してください',
      currentEmployment: '現在の雇用形態',
      fulltime: '正社員',
      temporary: '派遣社員',
      partTime: 'アルバイト',
      contractEmployee: '契約社員',
      subContracting: '業務委託',
      other: 'その他',
      recentAnnualIncome: '直近の年収',
      recentIncomelbl: '万円',
      desriedAnnualIncome: '希望の年収',
      desiredAnnuallbl: '万円',
      situation: '状況',
      beforeSup: 'サポート前',
      underSup: 'サポート中',
      notApplicable: '対象外',
      timeLag: '時期ずれ',
      EndSup: 'サポート終了',
      consultantInCharge: '担当コンサルタント',
      resume: '履歴書',
      cv: '職務経歴書',
      otherDocs: 'ポートフォリオ',
      portfolioURL: 'URLで指定する',
      porfolioSuppLabel: 'https://から始まる形式で入力してください',
      portfolioPDF: 'PDFをアップロードする',
      portFolioButton: 'PDFをアップロードする',
      note: '推薦文',
      footer: '',
      invalidCount: '{{count}}個の必須項目が未入力です',
      invalidCountMsg: '個の必須項目が未入力です',
      submitPreviewlbl: '内容の確認へ',
      otherDocsLblMsg: 'ご選択の上、ご入力ください。',
      enterPDF: 'PDFをアップロードする',
      confirmationLbl: '以上の内容で追加しますか？',
      footerToEdit: ' 編集する',
      recommend: ' 追加する',
      yearErrorMessage: '登録可能な年齢は18歳～70歳です',
      updateButton: '更新',
    },
    companyAccount: {
      common: {
        numberLabel: '電話番号',
        addressLabel: '所在地',
        initialPublicOfferingLabel: '株式公開',
        respresentativeNameLabel: '代表者名',
        companyIndustryLabel: '業界',
        yearOfEstablishmentLabel: '設立年',
        closingMonthLabel: '決算月',
        companyHPLabel: '会社HP',
        numberOfEmployeesLabel: '従業員数',
        companyProfileLabel: '会社概要',
        businessContentLabel: '事業内容',
        industry: '業界',
      },
      create: {
        adHeading: '人材事業所（AD）アカウント作成',
        hrHeading: '採用企業（HR）アカウント作成',
        titleLabel: '会社アカウント作成',
        nameLabel: '企業名',
        lastName: '氏',
        firstName: '名',
        yearOfEstablishmentUnitLabel: '年',
        yearOfEstablishmentSampleLabel: '例）1980',
        closingMonthUnitLabel: '月',
        closingMonthSampleLabel: '例）3',
        man: '人',
        companyHPSampleLabel: 'https://から始まる形式で入力してください',
        textAreaLimit: '5000字以内で入力してください。',
        numberofRequiredFieldsLabel: '{{count}}個の必須項目が未入力です',
        verifyButtonLabel: '内容の確認へ',
        companyTitle: '人材事業所事業所 (AD) アカウント作成',
        typeKinds: '種別',
        companyName: '会社名',
        companyAddress: '会社住所',
        phoneNumber: '電話番号',
        ipo: '株式公開',
        contractType: '契約種別',
      },
      confirm: {
        titleLabel: '登録内容の確認',
        nameLabel: '所属企業',
        confirmationMessage: '以上の内容で作成しますか？',
        toEditButtonLabel: ' 編集する ',
        createButtonLabel: ' 作成する ',
      },
    },
    invitation: {
      common: {
        catch: 'HuuNoへようこそ！',
        text: 'のアカウントです',
        createButtonLabel: 'アカウント作成',
      },
      validation: {
        validating: 'リンクを有効化しています. . .',
        invalid: 'リンクが無効です。',
        contact: 'HuuNo運営者までご連絡ください。',
        invalidToken: 'Invalid Token',
      },
    },
    userProfileInvitation: {
      common: {
        profileImageLabel: 'プロフィール画像',
        companyNameLabel: '所属企業',
        accountTypeLabel: 'アカウント種別',
        fullNameLabel: '氏名',
        phoneNumberLabel: '電話番号',
        profileLabel: 'プロフィール',
      },
      create: {
        titleLabel: 'アカウント作成',
        lastNameLabel: '氏',
        firstNameLabel: '名',
        pleaseEnter: '入力してください',
        uploadImageButtonLabel: '画像をアップロードする',
        phoneNumberBelowLabelLabel: 'ハイフンなしで入力',
        selectLabel: '選択する',
        mostGoodIndustryLabel: '最も得意な業界を選択（1つ）',
        specialtyIndustryLabel: '得意業界を選択（4つ）',
        mostGoodJobLabel: '最も得意な職種を選択（1つ）',
        specialtyJobLabel: '得意職種を選択（4つ）',
        numberofRequiredFieldsLabel: '{{count}}個の必須項目が未入力です',
        verifyButtonLabel: '内容の確認へ',
        modal: {
          mostGoodIndustryTitle: '最も得意な業界を選択（1つまで）',
          specialtyIndustryTitle: '得意業界を選択（4つまで）',
          industryTitle: '業界を選択（5つ）',
          mostGoodJobTitle: '最も得意な職種を選択（1つまで）',
          specialtyJobTitle: '得意職種を選択（4つまで）',
          clearSelection: '選択項目をクリア',
          decideSelected: '決定する',
        },
      },
      confirm: {
        titleLabel: '登録内容の確認',
        mostGoodIndustryLabel: '最も得意な業界',
        specialtyIndustryLabel: '得意業界',
        mostGoodJobLabel: '最も得意な職種',
        specialtyJobLabel: '得意職種',
        toEditButtonLabel: ' 編集する ',
        createButtonLabel: ' 作成する ',
        confirmationMessage: '以上の内容で作成しますか？',
      },
    },
    corporateInfo: {
      companyName: '会社名',
      typeKind: '種別',
      companyAddress: '会社住所',
      phoneNum: '電話番号',
      initialPublicOffering: '株式公開',
      industry: '業界',
      representativeName: '代表者名',
      yrOfEstablishment: '設立年',
      yrOfEstablishmentSuffix: '{{year}}年',
      closingMonth: '決算月',
      closingMonthVal: '{{months}}月',
      companyHP: '会社HP',
      numOfEmployees: '従業員数',
      numOfEmployeesCount: '{{count}}人',
      companyProfile: '会社概要',
      businessContent: '事業内容',
    },
    suggestion: {
      proposed: '提案リクエスト',
      appointment: 'アポ',
      under_negotiation: '商談',
      contracted: '契約',
      rejected_cancelled: '不採用/キャンセル',
      proposingCompany: '提案企業',
      position: 'ポジション',
      correspondingSituation: '対応状況',
      proposing: '提案中',
      ended: '終了',
      listTitle: '「{{title}}」の提案一覧',
      modal: {
        resumeMsg: 'メッセージを送信する場合は提案を再開してください。',
        title: '{{title}}への提案状況',
        companyInfo: '所属企業情報',
        hqAddress: '本社住所',
        bestIndustry: '一番得意な業界',
        specialIndustry: '得意業界',
        bestOccupation: '一番得意な職種',
        specialOccupation: '得意職種  ',
        resumeProposal: '提案を再開する',
        cancelProposal: '提案をキャンセル',
        confirmCancelTitle: '提案をキャンセルしますか？',
        confirmCancelText: '後から提案を再開することができます。',
        canceledAlert: '提案をキャンセルしました。',
        rejectedAlert: '提案を不採用にしました。',
        companyTab: '会社情報',
        changeStatus: '提案ステータス変更',
        completedMsg: '提案が終了しました。',
      },
      changeStatusModal: {
        title: 'ステータスを変更',
        description: '変更後のステータスを選択してください。',
        failTitle: '不採用にしますか？',
        failText: '後から不採用を取り消すことができます。',
        fail: '不採用にする',
      },
      employingCompany: '提案会社',
      salesRep: '担当営業',
    },
    companyList: {
      createNewAccount: '新規アカウント作成',
      companyAccountListTitle: '会社アカウント一覧',
      companyAccountListTitleUnit: '件',
      listRetrievalUnsuccesfulMessage: '検索条件を変更して再度お試しください。',
      headers: {
        id: '企業ID',
        company: '会社名',
        name: '代表者名',
        tel: '電話番号',
        status: 'ステータス',
        certification: '契約種別',
      },
      search: {
        title: '検索する',
        keywordDropDownLabel: '会社名・代表者名・電話番号',
        statusDropDownLabel: 'ステータス',
        contractTypeDropDownLabel: '契約種別',
        submitBtn: '検索条件をクリア',
        clearBtn: '検索する',
      },
    },
    candidateSlideout: {
      modal: {
        title: '{{applicant}}さんの詳細',
      },
      tabs: {
        basicInfo: '基本情報',
        recommendationInformation: '推薦情報',
        curriculumVitaeAndPortfolio: '履歴書・ポートフォリオ',
      },
      basicProfile: '基本プロフィール',
      finalEducation: '最終学歴',
      'regular employee': '正社員',
      'college graduate': '大卒',
      'temporary staff': '派遣社員',
      'contract employee': '契約社員',
      outsourcing: '業務委託',
      'part-time job': 'アルバイト',
      others: 'その他',
      desiredAnnualIncome: '希望年収',
      memo: 'メモ',
      item: '件',
      addNotes: 'メモを追加する',
      fieldErrorMessage: '500字以内で入力してください。',
      add: '追加',
      testimonial: '推薦文',
      fillInYourNotes: 'メモを記入してください',
      addedMemo: 'メモを追加しました',
      updatedMemo: 'メモを更新しました',
      deletedMemo: 'メモを更新しました',
      failedToUpdateMemo: 'メモの更新に失敗しました',
      failedToCreateMemo: 'メモの作成に失敗しました',
      failedToDeleteMemo: 'メモの削除に失敗しました',
      checkDetails: '詳細を確認する',
      seeAll: 'すべて見る',
      close: '閉じる',
      deleteWarning: '削除した場合、復元はできなくなります',
      deleteResume: '履歴書を削除しますか？',
      deletePortfolio: 'ポートフォリオを削除しますか？',
    },
    userAccount: {
      title: '検索する',
      keyword: 'メールアドレス、名前',
      filterByStatus: 'ステータス',
      filterByPermission: '権限',
      selectPlaceHolder: '選択してください',
      clearFilter: '検索条件をクリア',
      searchBtn: '検索する',
      userAccountListLbl: 'メンバー一覧',
      inviteLbl: '招待する',
      listCount: '件',
      modaltitle: '招待を送る',
      modalSubLbl: '招待したい人のメールアドレスを入力してください',
      modalCancelBtn: 'キャンセル',
      modalInviteBtn: '招待する',
      successStatusChange: 'ステータスを変更しました。',
      changeStatusLbl: 'ステータスを変更する',
      cancelInviteBtn: 'キャンセル',
      changeStatusBtn: '決定する',
      activeSupLbl: '全ての機能を利用できます',
      archiveSupLbl: 'ログイン含め全ての機能が利用できなくなります',
      suspendedSupLbl: '求人などの閲覧のみ可能です',
      modalTitle: '変更後のステータスを選択してください',
      modalTitle1: '変更後の権限を選択してください',
      permissionParentSupLbl: 'メンバーの招待、会社情報の変更などが可能です',
      permissionChildSupLblHR: '求人票作成、推薦・提案の管理などが可能です',
      permissionChildSupLblAD: '求人に対してのアクションなどが可能です',
      permission: '権限を変更',
      successInvite: '招待を送りました。',
      successpermission: '許可を変更しました',
      statusExpired: '期限切れ',
    },
    accountList: {
      emptyList: {
        subTitle: 'まずは管理者ユーザーを招待しましょう。',
        invitedTxt: '{{companyName}}のアカウント管理者となるユーザーを招待してください。',
        inviteBtnTxt: '招待する',
      },
      inviteModal: {
        title: '株式会社HuuNo Newの管理者を招待する',
        description: '管理者にしたいユーザーのメールアドレスを入力してください',
        alert: 'メールアドレスは正しい形式で入力してください',
        close: 'キャンセル',
        invite: '招待する',
      },
      changeCompanyStatusModal: {
        status1: 'アクティブ',
        subStatus1: '全ての機能を利用できます',
        status2: '休止',
        subStatus2: '求人などの閲覧のみ可能です',
        status3: 'アーカイブ',
        subStatus3: 'ログイン含め全ての機能が利用できなくなります',
        submit: '決定する',
        cancel: 'キャンセル',
        label: '変更後のステータスを選択してください',
      },
      inviteUserModal: {
        title: '株式会社HuuNo Newの管理者を招待する',
        subTitle: '管理者にしたいユーザーのメールアドレスを入力してください',
        emailAuthMsg: 'メールアドレスは正しい形式で入力してください',
        inviteSuccessMsg: '招待を送りました。',
        updateSuccessMsg: 'ステータスを変更しました。',
      },
      companyActionButtons: {
        editCompanyInfoBtn: '会社情報編集',
        changeRefundPolicyBtn: '返金規定変更',
        changeStatusBtn: 'ステータス変更',
      },
      companyProfileCard: {
        title: '株式会社デザインオフィス',
        headerId: '企業ID：{{id}}',
        subTitle: '会社概要',
        location: '所在地',
        tel: '電話',
        yrOfEstablishment: '設立年',
        settlementMonth: '決済月',
        initialPublicOffering: '株式公開',
        numOfEmployees: '従業員数',
        companyProfile: '会社概要',
        businessContent: '事業内容',
        secondSubTitle: '返金規定',
        firstMonth: '1ヶ月目',
      },
      search: {
        numOfSubscribers: '契約者数',
        numOfSubscribersSuffix: '人',
        keywordDropDownLabel: 'メールアドレス、名前',
        statusLabel: 'ステータス',
        authorityLabel: '権限',
        submitBtn: '検索する',
        clearBtn: '検索条件をクリア',
      },
      userList: {
        name: '名前',
        emailAddress: 'メールアドレス',
        authority: '権限',
        status: 'ステータス',
        accountVerification: 'アカウント認証',
        invitedBySuffix: 'さんが招待',
        pseudoLogin: '疑似ログイン',
        listRetrievalUnsuccesfulMessage: '検索条件を変更して再度お試しください。',
        updateStatusButton: 'ステータスを変更する',
      },
      userStatusesList: {
        Active: 'アクティブ',
        Suspended: '休止',
        Archived: 'アーカイブ',
        Pending: '招待中',
        Locked: '期限切れ',
      },
      headerCountTitle: '契約者数',
      countSuffix: '人',
    },
    createCompany: {
      tabs: {
        hrTabLabel: '採用企業（HR）',
        adminTabLabel: '人材事業所（AD）',
      },
      hrTab: {
        title: '採用企業（HR）アカウント作成',
        companyNameLabel: '会社名',
        companyAddressLabel: '会社住所',
        telNumLabel: '電話番号',
        ipoLabel: '株式公開',
        refundPolicyTitle: '返金規定について',
        refundPolicyLabel: '入社後1ヶ月（30日）',
        refundPolicyDefaultVal: 'ダミーテキストダミーテキストダミーテキストダミーテキスト',
        confirmButton: '内容の確認へ',
        invalidFieldCount: '{{count}}個の必須項目が未入力です',
      },
      hrConf: {
        title: '採用企業（HR）アカウント作成',
        companyNameLabel: '会社名',
        companyAddressLabel: '会社住所',
        telNumLabel: '電話番号',
        ipoLabel: '株式公開',
        refundPolicyTitle: '返金規定について',
        refundPolicyLabel: '入社後1ヶ月（30日）',
        ipoOptions: {
          firstSectionOfTheTokyoStockExchange: '東証一部',
          secondSectionOfTheTokyoStockExchange: '東証二部',
          mothers: 'マザーズ',
          jasdaq: 'JASDAQ',
          unlisted: '非上場',
        },
        footerTxt: '以上の内容でアカウントを作成しますか？',
        footerTxtEdit: '上の内容でアカウントを編集しますか？',
        backButton: '戻る',
        editButton: '編集する',
        createButton: '作成する',
      },
    },
    createUserAccount: {
      title: '個人アカウント作成',
      profileImage: 'プロフィール画像',
      uploadLbl: '画像をアップロードする',
      company: '所属企業',
      accountType: 'アカウント種別',
      fullName: '氏名',
      lastName: '氏',
      firstName: '名',
      profile: 'プロフィール',
      fieldErrorMessage: '5000字以内で入力してください。',
      createButtonLabel: '作成する',
      toEditButtonLabel: '編集する',
      confirmDetails: '登録内容の確認',
    },
    aboutUs: {
      header: '会社アカウント編集',
      edit: '編集する',
    },
    searchCriteria: {
      SearchConditionList: '検索条件一覧',
      moreItems: '他{{count}}項目',
      savedSearchCriteria: '保存済みの検索条件',
      freeWord: 'フリーワード',
      detailedConditions: '詳細条件',
      seeAllTags: 'すべてみる（他{{tags}}項目)',
      SearchByThisCondition: 'この条件で検索する',
      deleteCriteriaSuccess: '保存した条件が削除されます。',
      deleteCriteriaFail: '保存された条件を削除できませんでした',
      noSavedSearch: '保存された基準はありません',
    },
    responseState: {
      proposing: '提案中',
      no_response: '未対応',
      responded: '返信済み',
      closed: '終了',
    },
    editUserAccount: {
      title: '個人アカウントの編集',
      confirmDetails: '変更を確認',
      confirmMessage: '上記内容で更新しますか？',
    },
    download: {
      error: 'こちらアクションは実行できません。',
    },
  },
}

export default ja
