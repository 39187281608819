import React from 'react'
import { selectJobPosting } from '../../../../store/job-postings/selectors'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AgentOnly from 'components/molecules/JobVacancySection/AgentOnly'
import MandatoryRequirements from 'components/molecules/JobVacancySection/MandatoryRequirements'
import ApplicationRequirements from 'components/molecules/JobVacancySection/ApplicationRequirements'
import TreatmentSalaryWelfare from 'components/molecules/JobVacancySection/TreatmentSalaryWelfare'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import { useHistory } from 'react-router-dom'
import { Button } from 'components'

function JobVacancyTab({ editable = true }) {
  const job_posting = useSelector(selectJobPosting)
  const { t } = useTranslation('translation', { keyPrefix: 'jobVacancies' })
  const user = useSelector(selectAuthUser)
  const account_type_HR = accountType(user) === 'HR'
  const history = useHistory()
  const referralType = job_posting?.job_posting_type === 'referral'
  const handleClick = () => {
    localStorage.setItem(
      job_posting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(job_posting)
    )
    history.push({
      pathname: referralType ? '/new-job-post/step1/' : '/new-job-post-proposal/step1/',
      state: { job_posting_data: job_posting, multipleStep: true },
    })
  }

  if (job_posting === null) return null

  return (
    <>
      <div className="evaluation__leftMainCont evaluation__leftMainCont-jobVacancyWidth">
        <h2 className="evaluation__leftMainTitle evaluation__leftMainTitle-jobVacancyMainTitle">
          {referralType ? t('publishingSettings.title') : t('basicInformation.title')}
          {account_type_HR && (
            <Button variant="text" onClick={handleClick} disabled={!editable}>
              {t('publishingSettings.editButton')}
            </Button>
          )}
        </h2>
        <p className="evaluation__leftMainTitleSup">{t('publishingSettings.subTitle')}</p>
        {referralType ? (
          <dl className="evaluation__jobPostingDl">
            <dt className="evaluation__jobPostingDt">{t('publishingSettings.sendScoutEmail')}</dt>
            <dd className="evaluation__jobPostingDd">{job_posting.sending_scout_email}</dd>
            <dt className="evaluation__jobPostingDt">{t('publishingSettings.postingOnRecruitingMedia')}</dt>
            <dd className="evaluation__jobPostingDd">{job_posting.posting_recruitment_media}</dd>
          </dl>
        ) : (
          <dl>
            <dt className="evaluation__jobPostingDt evaluation__jobPostingDt-column">
              {t('basicInformation.advertisingBudget')}
            </dt>
            <dd className="evaluation__jobPostingDd evaluation__jobPostingDd-column">
              {job_posting.min_advertising_budget}~{job_posting.max_advertising_budget}
              {t('publishingSettings.yen')}
            </dd>
            <dt className="evaluation__jobPostingDt evaluation__jobPostingDt-column">
              {' '}
              {t('basicInformation.proposalLimit')}
            </dt>
            <dd className="evaluation__jobPostingDd evaluation__jobPostingDd-column">
              {job_posting.number_proposal_request_accepted}
              {t('publishingSettings.connoisseur')}
            </dd>
            <div className="evaluation__mediaInfoArea">
              <div className="evaluation__mediaInfoHead"> {t('basicInformation.mediaInformation')}</div>
              <div className="evaluation__mediaBlock">
                <dt className="evaluation__jobPostingDt evaluation__jobPostingDt-column">
                  {t('basicInformation.postedMedia')}
                </dt>
                <dd className="evaluation__jobPostingDd evaluation__jobPostingDd-column">
                  {job_posting?.current_post_media?.map((job, index) => {
                    return <li key={index}>{job.name}</li>
                  })}
                </dd>
                <dt className="evaluation__jobPostingDt evaluation__jobPostingDt-column">
                  {t('basicInformation.pastMedia')}
                </dt>
                <dd className="evaluation__jobPostingDd evaluation__jobPostingDd-column">
                  {job_posting.past_publication_medium?.map((job, index) => {
                    return <li key={index}>{job.name}</li>
                  })}
                </dd>
                <dt className="evaluation__jobPostingDt evaluation__jobPostingDt-column">
                  {' '}
                  {t('basicInformation.mediaInterest')}
                </dt>
                <dd className="evaluation__jobPostingDd evaluation__jobPostingDd-column">
                  {job_posting.media_of_interest?.map((job, index) => {
                    return <li key={index}>{job.name}</li>
                  })}
                </dd>
              </div>
            </div>
          </dl>
        )}
        <div className="jobvacancies">
          {referralType && <AgentOnly accountTypeHR={account_type_HR} editable={editable} />}
          <MandatoryRequirements accountTypeHR={account_type_HR} editable={editable} />
          <ApplicationRequirements accountTypeHR={account_type_HR} editable={editable} />
          <TreatmentSalaryWelfare accountTypeHR={account_type_HR} editable={editable} />
        </div>
      </div>
    </>
  )
}

JobVacancyTab.propTypes = {
  editable: PropTypes.bool,
}

export default JobVacancyTab
