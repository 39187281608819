import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { DATE_FORMAT } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import PropTypes from 'prop-types'

import { searchEvaluation, getUserEvaluations } from 'services/evaluation'
import { useParams } from 'react-router-dom'
import { actionSetSearchCriteria } from 'store/evaluation/actionCreators'
import { Pagination } from 'components/molecules'

function EvaluationThread({ activeTab, userId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.evaluation.list || [])
  const { lastPage, currentPage } = useSelector((state) => state.evaluation.listMeta || [])

  const jobPostingDetails = useSelector((state) => state.evaluation.jobPostingDetails || [])
  const isJobPostingDetailsNotEmpty =
    jobPostingDetails?.availablePosition?.parent?.name && jobPostingDetails?.availablePosition?.name

  let { id: jobPostingId } = useParams()

  const retrieveEvaluation = ({ user, job, page, tab }) => {
    if (typeof job !== 'undefined') {
      dispatch(searchEvaluation(job))
    } else if (typeof user !== 'undefined') {
      dispatch(
        getUserEvaluations(user, {
          page: page,
          limit: 10,
          sort: 'desc',
          sortBy: 'created_at',
          job_evaluation_filter: tab === 'all' ? null : tab,
        })
      )
    }
  }

  useEffect(() => {
    retrieveEvaluation({ user: userId, job: jobPostingId, page: 1, tab: activeTab })
  }, [jobPostingId, userId])

  useEffect(() => {
    dispatch(actionSetSearchCriteria({ page: 1, job_evaluation_filter: activeTab === 'all' ? null : activeTab }))
    retrieveEvaluation({ user: userId, job: jobPostingId, page: 1, tab: activeTab })
  }, [activeTab])

  const handlePageChange = (page) => {
    dispatch(actionSetSearchCriteria({ page: page }))
    retrieveEvaluation({ user: userId, job: jobPostingId, page: page, tab: activeTab })
  }

  return (
    <>
      {data.map((row, index) => (
        <li className="evaluation__leftEvaLi" key={index}>
          <div
            className={
              row.like
                ? 'evaluation__leftEvaLiIconArea evaluation__leftEvaLiIconArea-satis'
                : 'evaluation__leftEvaLiIconArea evaluation__leftEvaLiIconArea-curious'
            }
          >
            <p>{row.like ? t('evaluation.iconText.like') : t('evaluation.iconText.dislike')}</p>
          </div>
          <div className="evaluation__leftEvaLiTextArea">
            <p className="evaluation__leftEvaLiText">{row.message}</p>
            <div className="evaluation__leftEvaLiSupArea">
              <p className="evaluation__leftEvaLiSup">
                {isJobPostingDetailsNotEmpty &&
                  `${jobPostingDetails.availablePosition.parent.name} / ${jobPostingDetails.availablePosition.name}`}
              </p>
              <p className="evaluation__leftEvaLiSup">{dayjs(row.created_at).format(DATE_FORMAT)}</p>
            </div>
          </div>
        </li>
      ))}
      <Pagination page={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />
    </>
  )
}

EvaluationThread.propTypes = {
  activeTab: PropTypes.string,
  userId: PropTypes.number,
}

export default EvaluationThread
