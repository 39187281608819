import * as types from './actionTypes'
import { updateEvaluation, fetchEvaluations } from 'services/evaluation'
import { fetchJobPostingTabStats } from 'services/jobPostings'

const initialState = {
  list: null,
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  params: {
    keyword: '',
    limit: 30,
    page: 1,
    sort: 'desc',
    sortBy: 'created_at',
    job_evaluation_filter: null,
    job_posting_id: null,
  },
  pagination: {
    total: 0,
    currentPages: 1,
    lastPage: 1,
  },
  evaluationStats: {
    all: 0,
    like: 0,
    dislike: 0,
  },
  jobPostingDetails: {
    companyName: null,
    industry: null,
    occupation: null,
  },
  slideOut: {
    item: null,
  },
}
function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.GET_EVALUATION_LIST: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perpage)

      return {
        ...state,
        list: payload.data,

        jobPostingDetails: {
          companyName: payload.data[0]?.job_posting?.company_name ?? null,
          availablePosition: payload.data[0]?.job_posting?.available_position ?? null,
        },

        listMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },

        evaluationStats: {
          all: payload.meta.evaluationStats.all,
          like: payload.meta.evaluationStats.like,
          dislike: payload.meta.evaluationStats.dislike,
        },

        params: {
          ...state.params,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        params: {
          ...state.params,
          ...payload,
        },
      }
    case updateEvaluation.fulfilled: {
      const tmp = (state.list || []).slice()
      const slideoutIndex = tmp.findIndex((item) => item.id === payload.data.id)

      if (slideoutIndex !== -1) {
        tmp[slideoutIndex] = { ...tmp[slideoutIndex], ...payload.data }
      }

      return {
        ...state,
        list: tmp,
        slideOut: {
          item: payload.data,
        },
      }
    }

    case fetchEvaluations.fulfilled: {
      const { data, meta } = payload
      return {
        ...state,
        list: data,
        listMeta: {
          totalCount: meta.total,
          currentPage: meta.currentPage,
          lastPage: meta.lastPage,
          pageSize: meta.perPage,
        },
        evaluationStats: {
          all: meta.evaluationStats.all,
          like: meta.evaluationStats.like,
          dislike: meta.evaluationStats.dislike,
        },
      }
    }
    case types.SET_EVALUATION_SLIDEOUT_ITEM: {
      return {
        ...state,
        slideOut: {
          item: payload.data,
        },
      }
    }
    case fetchJobPostingTabStats.fulfilled:
      return {
        ...state,
        evaluationStats: {
          ...state.evaluationStats,
          all: payload.tab !== 'evaluation' ? payload.stats.evaluation : state.evaluationStats.all,
        },
      }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  const totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return Number(newCurrentPage)
}

export default reducer
