import React, { useState, useEffect } from 'react'
import { FormInterlock, FormItemLabel, Radio } from 'components'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { EMPLOYMENT_STATUS } from 'utils/constants'
import { objectFlip } from 'utils/helper'

const flipedStatus = objectFlip(EMPLOYMENT_STATUS)

const EmploymentStatus = (props) => {
  const { editmode, successEmployment, handleOnChange, showOthers, empStat, candidateSelected } = props
  const [stat, setStatus] = useState('')
  const { register } = useForm({})

  useEffect(() => {
    setStatus(empStat)
    if (
      empStat !== 'Regular employee' &&
      empStat !== 'Contract employee' &&
      empStat !== 'Temporary Staff' &&
      empStat !== 'Outsourcing' &&
      empStat !== 'Part-time job'
    ) {
      setStatus('Others')
    }
  }, [empStat])

  const emplyomentStatus = candidateSelected
    ? props?.data.current_employment_status === 'Others'
      ? props?.data.other_status
      : flipedStatus[props?.data.current_employment_status]
    : props?.finalData.current_employment_status === 'Others'
    ? props?.finalData.other_status
    : flipedStatus[props?.finalData.current_employment_status]

  return (
    <div
      className={
        editmode ? `form__group form__group-mgb0 required ${successEmployment}` : `form__group form__group-mgb0`
      }
    >
      <div className="form__groupCheckArea">
        <FormItemLabel label="現在の雇用形態" />
        {editmode && !candidateSelected ? (
          <>
            <div className="form__groupCheck form__groupCheck-flex">
              <p className="form__groupCheckItem form__groupCheckItem-w50b">
                <Radio
                  id="Regular employee"
                  name="current_employment_status"
                  {...register('current_employment_status', { required: 'This is required' })}
                  label="正社員"
                  checked={stat === 'Regular employee'}
                  value="Regular employee"
                  onChange={(e) => handleOnChange(e)}
                />
                <label htmlFor="Regular employee" className="form__checkLabel">
                  正社員
                </label>
              </p>
              <p className="form__groupCheckItem form__groupCheckItem-w50b2">
                <Radio
                  id="Contract employee"
                  name="current_employment_status"
                  {...register('current_employment_status')}
                  checked={stat === 'Contract employee'}
                  label="契約社員"
                  value="Contract employee"
                  onChange={handleOnChange}
                />
                <label htmlFor="Contract employee" className="form__checkLabel">
                  契約社員
                </label>
              </p>
              <p className="form__groupCheckItem form__groupCheckItem-w50b">
                <Radio
                  id="Temporary Staff"
                  name="current_employment_status"
                  {...register('current_employment_status')}
                  checked={stat === 'Temporary Staff'}
                  label="派遣社員"
                  value="Temporary Staff"
                  onChange={(e) => handleOnChange(e)}
                />
                <label htmlFor="Temporary Staff" className="form__checkLabel">
                  派遣社員
                </label>
              </p>
              <p className="form__groupCheckItem form__groupCheckItem-w50b2">
                <Radio
                  id="Outsourcing"
                  name="current_employment_status"
                  {...register('current_employment_status', { required: 'This is required' })}
                  label="業務委託"
                  value="Outsourcing"
                  checked={stat === 'Outsourcing'}
                  onChange={handleOnChange}
                />
                <label htmlFor="Outsourcing" className="form__checkLabel">
                  業務委託
                </label>
              </p>
              <p className="form__groupCheckItem form__groupCheckItem-w50b">
                <Radio
                  id="Part-time job"
                  name="current_employment_status"
                  {...register('current_employment_status')}
                  checked={stat === 'Part-time job'}
                  value="Part-time job"
                  label="アルバイト"
                  onChange={(e) => handleOnChange(e)}
                />
                <label htmlFor="Part-time job" className="form__checkLabel">
                  アルバイト
                </label>
              </p>
              <div className="form__groupCheckItem form__groupCheckItem-w50b2">
                <Radio
                  id="Others"
                  name="current_employment_status"
                  {...register('current_employment_status')}
                  checked={stat === 'Others' && empStat !== ''}
                  label="その他"
                  value={'Others'}
                  onChange={(e) => handleOnChange(e)}
                />
                <label htmlFor="Others" className="form__checkLabel">
                  その他
                </label>
                <FormInterlock active={showOthers}>
                  <div className="form__groupFlex form__group-mgt">
                    <input
                      type="text"
                      name="other_status"
                      {...register('other_status')}
                      defaultValue={props?.data?.other_status}
                      onChange={handleOnChange}
                      className="form__inputText inputCheck boolCheck"
                    />
                  </div>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">140字以内でご入力ください。</p>
                  </div>
                </FormInterlock>
              </div>
            </div>
            <div className="form__supArea form__supArea-flex">
              <p className="form__alert">{props.errors?.current_employment_status}</p>
            </div>
          </>
        ) : (
          <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
            <p className="recommendation__dt">{emplyomentStatus}</p>
          </div>
        )}
      </div>
    </div>
  )
}

EmploymentStatus.propTypes = {
  data: PropTypes.object,
  editmode: PropTypes.bool,
  successEmployment: PropTypes.string,
  handleOnChange: PropTypes.func,
  finalData: PropTypes.object,
  showOthers: PropTypes.bool,
  empStat: PropTypes.string,
  errors: PropTypes.object,
  candidateSelected: PropTypes.bool,
  //mode: PropTypes.string,
}

export default EmploymentStatus
