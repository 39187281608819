import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(LocalizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('ja')

const ja = dayjs.Ls.ja

ja.formats.llll = 'YYYY年MM月DD日(dd) HH:mm'
ja.formats.ll = 'YYYY年MM月DD日'
